import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Hidden, CircularProgress } from '@material-ui/core'
import Portfolio from '../portfolio/AsSection'

import { API_URL, STATIC_URL } from '../utils/Constant'
import { useFetchMeta, useFetchImageHeader } from '../utils/Fetch'
import { initAnimation, initTestimonialsPlugin, unsubTestimonialsPlugin } from '../assets/js/CustomScript'

import '../assets/css/portfolio.css'

const useStyles = makeStyles(theme => ({
    projectParent: {
        margin: '8px 0px 60px 0px',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    projectLogo: {
        [theme.breakpoints.up('md')]: {
            width: '40%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    projectDesc: {
        [theme.breakpoints.up('md')]: {
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '60%',
        },
        borderTop: '2px solid orange',
    }
}))

const Line = ({ width }) => {
    return <hr className="line" style={{ width }}/>
}

const PrintProject = ({ classes, projects }) => {
    return (
        <div className={clsx(classes.projectParent)}>
            <div className={clsx(classes.projectLogo)}>
                <div className="mbr-figure">
                    <img src={STATIC_URL + projects.project_logo} alt="Logo"/>
                </div>
            </div>
            <div className={clsx(classes.projectDesc)}>
                <div className="cards-container">
                    <div className="card px-3 align-left col-12">
                        <div className="panel-item p-4 d-flex align-items-start">
                            <Hidden smDown>
                                <div className="card-img pr-3 mr-3">
                                    <h3 className="img-text d-flex align-items-center justify-content-center">
                                        { projects.seq === '-' ? '' : projects.seq }
                                    </h3>
                                </div>
                            </Hidden>
                            <div className="card-text">
                                <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-5">
                                    { projects.project_name }
                                </h4>
                                <p className="mbr-content-text mbr-fonts-style display-5">
                                    { projects.project_desc }
                                </p>
                                <p className="mbr-content-text mbr-fonts-style display-5 mt-3">                                                                    
                                    Technologies: { projects.project_technology }
                                </p>                                                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ({ location: { pathname } }) => {
    const { metaData } = useFetchMeta(pathname)
    const { imgUrl } = useFetchImageHeader('portfolio_header')

    const classes = useStyles()

    const [preamble, setPreamble] = useState('')
    const [loading, setLoading] = useState(false)

    const [porfolios, setPortfolios] = useState([])

    useEffect(() => {
        // unsub
        unsubTestimonialsPlugin()

        initAnimation()
        initTestimonialsPlugin()

        const getPreamble = async () => {
            return await fetch(API_URL.PORTFOLIO_PREAMBLE)
                .then(r => r.json())
        }
        setLoading(true)
        getPreamble().then(r => {
            let { status, data } = r
            if(status) {
                setPreamble(data)
            }
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        const getPortfolios = async () => {
            return await fetch(API_URL.PORTFOLIO)
                .then(r => r.json())
        }
        setLoading(true)
        getPortfolios().then(r => {
            let { status, data } = r
            if(status) {
                setPortfolios(data)
            }
            setLoading(false)
        })
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>Portfolio</title>
                <meta name="description" content={metaData ? metaData.description : ''} /> 
                <meta name="keywords" content={metaData ? metaData.keywords : ''} />
            </Helmet>
            <section className="cid-rxT0hsAFtD mbr-parallax-background" id="header2-9n" style={{ backgroundImage: `url(${imgUrl})`}}>
                <div className="mbr-overlay" style={{ opacity: 0.5, backgroundColor: 'rgb(0, 0, 0)'}}></div>
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">PORTFOLIO</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section info3 cid-ryy9wwaaoa" id="info3-a7">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="media-container-column title col-12 col-md-10">                
                            <h3 className="mbr-section-subtitle align-left mbr-light mbr-white pb-3 mbr-fonts-style display-5">
                                { preamble }                                
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <section className="mbr-section article content10 cid-ryy6Dn6XNL" id="content10-a4">
                    <div className="container">
                        <div className="inner-container" style={{ width: '66%' }}>
                            <div className="section-text align-center mbr-white mbr-fonts-style display-2">
                                <CircularProgress color="secondary" />
                            </div>
                        </div>
                    </div>
                </section>
            }
            {
                !loading && porfolios && porfolios.map((client, idx) => {
                    return (
                        <Fragment key={'cl_' + idx}>
                            <section className="mbr-section article content10 cid-ryy6Dn6XNL" id="content10-a4">
                                <div className="container">
                                    <div className="inner-container" style={{ width: '66%' }}>
                                        <Line width="25%" />
                                        <div className="section-text align-center mbr-white mbr-fonts-style display-2">
                                            Project with { client.client_name }
                                        </div>
                                        <Line width="25%"/>
                                    </div>
                                </div>
                            </section>
                            <section className="counters4 counters cid-rxY1NVWRAH" id="counters4-9u">
                                <div className="container pt-4 mt-2">        
                                    <h3 className="mbr-section-subtitle pb-5 align-center mbr-fonts-style display-5">
                                        { client.portfolio_description }
                                    </h3>
                                    {
                                        client.projects && client.projects.map((p, idx) => { console.log('ADP :::', p)
                                            return <PrintProject classes={classes} projects={p} key={'p_' + idx}/>
                                        })
                                    }                     
                                </div>
                            </section>
                        </Fragment>
                    )
                })
            }
            <Portfolio />          
        </Fragment>
    )
}