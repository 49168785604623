import React, { Fragment } from 'react'

export default () => {
    return (
    <Fragment>
        <section className="mbr-section info3 cid-rwzoURlqyF" id="info3-7j" style={{backgroundColor: "white", marginTop: 40}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="media-container-column title col-12 col-md-10">
                        <h2 className="align-left mbr-bold mbr-white pb-3 mbr-fonts-style display-2">
                            CONTACT US NOW!
                        </h2>
                        <h3 className="mbr-section-subtitle align-left mbr-light mbr-white pb-3 mbr-fonts-style display-5">
                            Want to go further and learn to code? Hit the button to discover our program.
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <section className="mbr-section content8 cid-rwzoYuNqwq" id="content8-7k"  style={{backgroundColor: "white"}}>
            <div className="container">
                <div className="media-container-row title">
                    <div className="col-12 col-md-8">
                        <div className="mbr-section-btn align-center">
                            <a className="btn btn-primary display-4" href="/contactUs#form4-4s">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
    )
}