import { useState, useEffect } from 'react'
import { API_URL, STATIC_URL } from './Constant'

function useFetch(initialUrl) {    
    const [isLoading, setIsLoading] = useState(true)
    const [fetchedData, setFetchedData] = useState({ status: false })

    useEffect(() => {
        let unmounted = false
        const initialData = { status: false }

        const handleFetchResponse = response => {
            if (unmounted) return initialData
            setIsLoading(false)
            
            return response.ok && response.json ? response.json() : initialData
        }

        const fetchData = async () => {
            setIsLoading(true)
            return await fetch(initialUrl, { 
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(handleFetchResponse)
            .catch(handleFetchResponse)
        }

        if (initialUrl && !unmounted) {
            fetchData().then(data => {
                !unmounted && setFetchedData(data)
            })
        }

        return () => {            
            unmounted = true
        }
    }, [initialUrl])
    
    return { isLoading, data: fetchedData }
}

function useFetchMeta(param) {
    const { data: { data: metaData, status, message }, isLoading } = 
        useFetch(API_URL.PAGE_META + "?p=" + encodeURIComponent(param))
    return { metaData, status, message, isLoading }
}

function useFetchImageHeader(headerCategory) {
    const { data: { data, status, message }, isLoading } = 
        useFetch(API_URL.CLIENT_IMAGE_HEADER + "?cat=" + encodeURIComponent(headerCategory))
    if(status) {
        return { imgUrl: STATIC_URL + data, status, message, isLoading }
    }
    return { imgUrl: '', status, message, isLoading }
}

export { useFetch, useFetchMeta, useFetchImageHeader }