import React, { useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core'
import { API_URL, STATIC_URL } from '../utils/Constant'
import '../assets/css/bootcamp.css'

export default () => {
    // const classes = useStyles()
    const [instructor, setInstructor] = useState([])
    
    useEffect(() => {
        const fetchClient = async () => {
            return await fetch(API_URL.BOOTCAMP_INSTRUCTOR)
                .then(resp => resp.json())
                .catch((error) => {
                    console.log("bootcamp", error.message)
                    return { status: false }
                })
        }
        fetchClient().then(({ status, data }) => {
            if(status){
                setInstructor(data)
            }
        })
    }, [])

    return (
        <section className="team1 cid-rwzkP9v3e9" id="team1-7a">
            <div className="container align-center">
                <hr className="line" style={{ width: '17%', backgroundColor: 'black', height: 2, marginTop: 0, marginBottom: 0, }} />
                    <div className="section-text align-center mbr-black mbr-fonts-style display-2" style={{paddingTop: 32, paddingBottom: 32}}>Instructors</div>
                <hr className="line" style={{ width: '17%', backgroundColor: 'black', height: 2, marginTop: 0, marginBottom: 50 }} />
                <h3 className="pb-5 mbr-section-subtitle mbr-fonts-style mbr-light display-5">
                    Led by experienced instructors, we produce quality developers that are sought after by companies.
                </h3>
                <div className="row media-row">
                    {
                        instructor.map((m, idx) => {
                            return (
                                <div className="team-item col-lg-3 col-md-6" key={idx}>
                                    <div className="item-image">
                                        <img src={STATIC_URL + m.instructor_photo_path} alt="Bootcamp Instructor" title=""/>
                                    </div>
                                    <div className="item-caption py-3">
                                        <div className="item-name px-2">
                                            <p className="mbr-fonts-style display-5">
                                                { m.instructor_name }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }                                          
                </div>
            </div>            
        </section>      
    )
}