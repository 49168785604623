import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import {API_URL} from '../utils/Constant'
import File from '../assets/file/Company_Profile_BSP_2021.pdf'
import { HashLink as Link } from 'react-router-hash-link'

export default () => {
    const [data, setData] = useState([])

    // const goToWa = () => {
    //     let url = "https://api.whatsapp.com/send?phone=" + 
    //         (data['WA'] ? data['WA'].value : "") + "&text=" + encodeURIComponent(data['WA_MSG'] ? data['WA_MSG'].value : "")
    //     window.open(url, "_blank")
    // }

    useEffect(() => {        
        async function fetchCompInfo() {
            const resp =  await fetch(API_URL.COMPANY_INFO, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",                
                }
            })
            .then((resp) => resp.json())
            .catch(err => console.log(err))

            if(resp) {
                const { data } = resp
                const reduceMap = data.reduce((resultMap, one) => {
                    const { code } = one                
                    if (!resultMap[code]) {
                        resultMap[code] = one
                    }                
                    return resultMap
                }, [])
                setData(reduceMap)
            }
        }
        fetchCompInfo()
    }, [])

    return (
        <section className="cid-rwgmCrVE7B" id="footer1-51">
            <div className="container">
                <div className="media-container-row content text-white">
                    <div className="col-12 col-md-3">
                        <div className="media-wrap">
                                <iframe 
                                    title="bsp-map"
                                    frameBorder="0" 
                                    style={{width: '206px', height: '233px'}}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.0783413746717!2d107.57014211432754!3d-6.881218295027513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e685af3df3e7%3A0x230873a4329685e1!2sPT+Bee+Solution+Partners!5e0!3m2!1sen!2sid!4v1563163885149!5m2!1sen!2sid" 
                                    allowFullScreen="">
                                </iframe>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mbr-fonts-style display-7">
                        <h5 className="pb-3">
                            Contacts
                        </h5>
                        <p className="mbr-text">
                            Address: <br/>
                            { data["ADDRESS"] && data["ADDRESS"].value}
                            <br /><br />
                            { data["EMAIL"] && 
                                'Email: ' + data["EMAIL"].value
                            } 
                            { data["EMAIL"] && <br /> }
                            { data["PHONE"] && 
                                'Phone: ' + data["PHONE"].value 
                            }
                            { data["PHONE"] && <br /> }
                            { data["WA"] && 
                                'Mobile/Whatsapp: ' + data["WA"].value
                            }                            
                        </p>
                    </div>
                    <div className="col-12 col-md-2 mbr-fonts-style display-7">
                        <h5 className="pb-3">
                            Navigation
                        </h5>
                        <p className="mbr-text">
                            <a className="text-primary" href="/">Home</a><br />
                            <a className="text-primary" href="/#content10-9p">Our Services</a><br />
                            <a className="text-primary" href="/portfolio">Portfolio</a><br />
                            <a className="text-primary" href="/aboutUs">About Us</a><br />
                            {/* <a className="text-primary" href="/blog">Blog</a><br /> */}
                            <a className="text-primary" href="/career">Career</a><br />
                            <a className="text-primary" href="/contactUs#form4-4s">Contact Us</a><br />
                        </p>
                    </div>
                    <div className="col-12 col-md-3 mbr-fonts-style display-7">
                        <h5 className="pb-3">
                            Our Products
                        </h5>
                        <p className="mbr-text">
                            <Link className="text-primary" to="/products#toggle2-8o0">BSP Asset Management System</Link><br />
                            <Link className="text-primary" to="/products#toggle2-8p1">Bee Attendance System</Link><br />
                            <Link className="text-primary" to="/products#toggle2-8o2">Bee GMes</Link><br />
                            <Link className="text-primary" to="/products#toggle2-8p3">BeeLib</Link><br /> 
                            <br />
                            Download
                            <br />
                            <a className="text-primary" href={File} target="_blank" rel="noopener noreferrer">Company Profile</a>
                        </p>
                    </div>
                </div>
                <div className="footer-lower">
                    <div className="media-container-row">
                        <div className="col-sm-12">
                            <hr />
                        </div>
                    </div>
                    <div className="media-container-row mbr-white">
                        <div className="col-sm-6 copyright">
                            <p className="mbr-text mbr-fonts-style display-7">
                                © Copyright { new Date().getFullYear() } PT Bee Solution Partners - All Rights Reserved
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="social-list align-right">
                                <div className="soc-item">
                                    <a href="https://twitter.com/pt_bsp" target="_blank" rel="noopener noreferrer">
                                        <span className="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"></span>
                                    </a>
                                </div>
                                <div className="soc-item">
                                    <a href="https://www.facebook.com/BeeSolutionPartners" target="_blank" rel="noopener noreferrer">
                                        <span className="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"></span>
                                    </a>
                                </div>
                                <div className="soc-item">
                                    <a href="https://www.youtube.com/channel/UCrQTXSkXaH8pV3e5K2S8AGQ" target="_blank" rel="noopener noreferrer">
                                        <span className="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"></span>
                                    </a>
                                </div>
                                <div className="soc-item">
                                    <a href="https://www.instagram.com/pt_bsp" target="_blank" rel="noopener noreferrer">
                                        <span className="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"></span>
                                    </a>
                                </div>
                                <div className="soc-item">
                                    <a href="https://www.linkedin.com/company/pt-bee-solution-partners" target="_blank" rel="noopener noreferrer">
                                        <span className="mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"></span>
                                    </a>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}