import React from 'react'
import PropType from 'prop-types'

const SectionTitle = ({ title }) => {
    return (
        <div className="container">
            <div className="inner-container" style={{ width: "66%" }}>
                <hr className="line" style={{ width: "25%" }}/>
                <div className="section-text align-center mbr-white mbr-fonts-style display-2">
                    { title }
                </div>
                <hr className="line" style={{ width: "25%" }}/>
            </div>
        </div>
    )
}

SectionTitle.prototype = {
    title : PropType.string.isRequired
}
export default SectionTitle