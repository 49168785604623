import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core'
import { useFetchMeta, useFetchImageHeader } from '../utils/Fetch'
import Product from '../products/AsSection'
import CircularProgress from '@material-ui/core/CircularProgress'

import {API_URL, STATIC_URL} from '../utils/Constant'
import ImgGalleryDialog from '../common/ImgGalleryDialog'
import {Accordion} from 'react-bootstrap'
import {withStyles} from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'



const useStyles = makeStyles(theme => ({
    icon: {
        color: 'rgb(249, 133, 51) !important',
        fill: 'rgb(249, 133, 51) !important'
    },
    testA: {
        '&:hover' : {
            backgroundColor: '#342e37'
        },
    }
}))

const AccordionReason = withStyles({
    expanded: {},
    expandIcon: {
      '&$expanded': {
            transform: 'rotateX(180deg)',
        },
        order: -1,
        fontSize: '2.1rem',
        color: 'black',
        paddingRight: '3rem',
    },
    root: {
        marginBottom: '0px',
        marginTop: '0px',
        fontStyle: 'normal',
        border: '1px solid #dfdfdf',
        paddingRight: '2rem',
        padding: '1rem 2rem',
        backgroundColor: '#f2f2f2'
    },
    content: {
        '&$expanded': {
            margin: '0px',
            // paddingLeft: '2rem'
        }
    }
})(MuiAccordionSummary)

const ToggleReason = withStyles({
    root: {   
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
})(MuiAccordion)

const AccordionSum = withStyles({
      expanded: {},
      expandIcon: {
        '&$expanded': {
            transform: 'rotateX(180deg)',
        },
        order: -1,
        fontSize: '2.1rem',
        color: 'black',
        paddingRight: '3rem'
      },
    root: {
        marginBottom: '0px',
        marginTop: '0px',
        fontStyle: 'normal',
        border: '1px solid #dfdfdf',
        paddingRight: '2rem',
        padding: '1rem 2rem',
        backgroundColor: '#f2f2f2'
    },
    content: {
    '&$expanded': {
        margin: '0px',
        // paddingLeft: '2rem'
    },
  },
})(MuiAccordionSummary)

const Toggle = withStyles({
    root: {   
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
    expanded: {},
})(MuiAccordion)

const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
    },
}))(MuiAccordionDetails);
export default ({ location: { pathname } }) => {
    const { metaData } = useFetchMeta(pathname)
    const classes = useStyles()
    const { imgUrl } = useFetchImageHeader('product_header')
    const [product, setProduct] = useState([])
    const [loading, setLoading] = useState(false)
    const [openGallery, setOpenGallery] = useState(false)
    const [selectedValue, setSelectedValue] = useState(0)
    const [expanded, setExpanded] = useState('panel')
    const [expandedReason, setExpandedReason] = useState('toggle')

    useEffect(() => {
        setLoading(true)
        const getProduct = async () => {
            return await fetch(API_URL.PRODUCT)
                .then(r => r.json())
                .catch(r => console.log(r))
        }
        getProduct().then(r => {
            let { data, status, message } = r

            setLoading(false)
            if(status) {
                setProduct(data)
            } else {
                console.log(message)
            }
        })
    }, [])

    const handleClick = (id) => () => {
        setSelectedValue(id)
        setOpenGallery(true)
    }
    const handleClose = () => {
        setOpenGallery(false)
        setSelectedValue(0)
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded && panel)
        console.log(panel, isExpanded, expanded)
    };
    
    const handleChangeReason = (toggle) => (event, isExpanded) => {
        setExpandedReason(isExpanded && toggle)
        console.log(toggle, isExpanded, expandedReason)
    };

    return (
        <Fragment>
            <Helmet>
                <title>Products</title>
                <meta name="description" content={metaData != null ? metaData.description: ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
            </Helmet>
            <section className="cid-rx7ORq0YcQ mbr-parallax-background" id="header2-8m" style={{ backgroundImage: `url(${imgUrl})`}}>
                <div className="mbr-overlay" style={{ opacity: 0.5, backgroundColor: 'rgb(0, 0, 0)'}}></div>
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">PRODUCTS</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-gallery mbr-slider-carousel cid-ryrt7UAZ4N" id="gallery3-9n">
                <div className="container">
                    <div>
                        <div >
                            <div className="mbr-gallery-layout-default">                                
                                <div style={{ textAlign: 'center' }}>
                                    { product.map((cl, id) => {
                                        return ( 
                                            <div className="mbr-gallery-item mbr-gallery-item--p3" data-video-url="false" data-tags="Awesome" key={id}>
                                                <div onClick={handleClick(id)}>
                                                    <img src={STATIC_URL + cl.product_logo} alt="" title="" className="img-responsive clients-img"/>
                                                    <span className="icon-focus"></span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="clearfix">
                                </div>
                            </div>
                        </div>
                        <ImgGalleryDialog onClose={handleClose} open={openGallery} selectedValue={selectedValue} imgList={product.map(m => STATIC_URL + m.product_logo)} />
                    </div>
                </div>
            </section>
            
            <div className="container">
                {loading && <CircularProgress size={100} className={classes.loading} />}
                {product.map((fe,index) => {
                    if(fe.seq === '0') {
                        return (
                            <div id={`toggle2-8o${index}`} style={{paddingTop: '30px'}}>
                                <div className="toggle2 cid-rx7ORu5JYR">
                                    <div className="col-md-12">
                                        <div className="media-container-row">
                                            <div className="toggle-content">
                                                <h2 className="mbr-section-title pb-3 align-left mbr-fonts-style display-5">
                                                    <a href={fe.url_youtube} target="_blank">
                                                        <strong> {fe.product_name} </strong>
                                                    </a>
                                                    {fe.product_desc}
                                                </h2>
                                                <Accordion>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <Toggle expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={`accordion0-${index}`}>
                                                                <h4 className="mbr-fonts-style display-5" style={{marginBottom: '0px'}}>
                                                                <AccordionSum
                                                                    expandIcon={<span className="mbri-arrow-down"/>}> 
                                                                    Features 
                                                                </AccordionSum>  
                                                                </h4>
                                                                <AccordionDetails>
                                                                        <p className="mbr-fonts-style panel-text display-7"
                                                                            dangerouslySetInnerHTML={{ __html: fe.product_feature }}>
                                                                        </p>
                                                                </AccordionDetails>
                                                            </Toggle>
                                                            <ToggleReason expandedReason={expandedReason === `panel${index}`} onChange={handleChangeReason(`toggle${index}`)} key={`accordion1-${index}`}>
                                                                <h4 className="mbr-fonts-style display-5">
                                                                <AccordionReason
                                                                    expandIcon={<span className="mbri-arrow-down"/>}> 
                                                                    Why {fe.product_abbr}?
                                                                </AccordionReason> 
                                                                </h4>
                                                                <AccordionDetails>
                                                                    <p className="mbr-fonts-style panel-text display-7"
                                                                        dangerouslySetInnerHTML={{ __html: fe.product_reason }}>
                                                                    </p>
                                                                </AccordionDetails>
                                                            </ToggleReason>
                                                        </div>
                                                    </div>
                                                </Accordion>
                                            </div>
                                            <div className="mbr-figure" style={{width: '89%',  marginTop: '-6%'}}>
                                                <a href={fe.url_youtube}>
                                                    <img src={ STATIC_URL + fe.product_logo} alt=""/>
                                                </a>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="mbr-section-btn align-center" style={{marginBottom: '60px'}}>
                                            <a className="btn btn-primary display-4" 
                                                href={fe.url_youtube} target="_blank" rel="noopener noreferrer">
                                                LEARN MORE
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else if(fe.seq === '1') {
                        return (
                            <div id={`toggle2-8p${index}`} style={{paddingTop: '30px'}}>
                                <div className="toggle2 cid-rx7ORvQWtf" id={`toggle2-8p${index}`}>
                                    <div className="col-md-12">
                                        <div className="media-container-row">
                                            <div className="toggle-content">
                                                <br/>
                                                <h2 className="mbr-section-title pb-3 align-left mbr-fonts-style display-5">
                                                    <a href={fe.url_youtube} target="_blank">
                                                        <strong> {fe.product_name} </strong>
                                                    </a>
                                                    {fe.product_desc}
                                                </h2>
                                                <Accordion>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <Toggle expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={`accordion0-${index}`}>
                                                                <h4 className="mbr-fonts-style display-5" style={{marginBottom: '0px'}}>
                                                                <AccordionSum
                                                                    expandIcon={<span className="mbri-arrow-down"/>}> 
                                                                    Features
                                                                </AccordionSum>  
                                                                </h4>
                                                                <AccordionDetails>
                                                                        <p className="mbr-fonts-style panel-text display-7"
                                                                            dangerouslySetInnerHTML={{ __html: fe.product_feature }}>
                                                                        </p>
                                                                </AccordionDetails>
                                                            </Toggle>
                                                            <ToggleReason expandedReason={expandedReason === `panel${index}`} onChange={handleChangeReason(`toggle${index}`)} key={`accordion1-${index}`}>
                                                                <h4 className="mbr-fonts-style display-5">
                                                                <AccordionReason
                                                                    expandIcon={<span className="mbri-arrow-down"/>}> 
                                                                    {/* <span className="sign mbr-iconfont inactive mbri-arrow-down"></span> */}
                                                                   Why {fe.product_abbr}?
                                                                </AccordionReason> 
                                                                </h4>
                                                                <AccordionDetails>
                                                                    <p className="mbr-fonts-style panel-text display-7"
                                                                        dangerouslySetInnerHTML={{ __html: fe.product_reason }}>
                                                                    </p>
                                                                </AccordionDetails>
                                                            </ToggleReason>
                                                        </div>
                                                    </div>
                                                </Accordion>
                                            </div>
                                            <div className="mbr-figure" style={{width: '89%', marginTop: '-5%'}}>
                                                <a href={fe.url_youtube}>
                                                    <img src={ STATIC_URL + fe.product_logo} alt=""/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="mbr-section-btn align-center" style={{marginBottom: '60px'}}>
                                            <a className="btn btn-primary display-4" 
                                                href={fe.url_youtube} target="_blank" rel="noopener noreferrer">
                                                LEARN MORE
                                            </a>
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            <Product />
        </Fragment>
    )
}