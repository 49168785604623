import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { NotFound } from './error'

import './assets/css/animation.css'
import Home from './home'
import Bootcamp from './bootcamp'
import ITConsultant from './itConsultant'
import Products from './products'
import Portfolio from './portfolio'
import AboutUs from './aboutUs'
// import Blog from './blog'
import ContactUs from './contactUs'
import Career from './career'
import NavBar from './navbar'
import Footer from './layout/Footer'
import ApplyCareer from './career/Apply'

import TestComp from './error/test'

export default () => {
    const isTransparent = (path) => {
        const transparentNavBarList = [
            "/",
            "/bootcamp",
            "/itConsultant",
            "/products",
            "/contactUs",
            "/aboutUs",
            "/portfolio",
            // "/blog",
        ]
        return transparentNavBarList.find(f => path.indexOf(f) >= 0)
    }

    return <BrowserRouter>
        <Helmet 
            titleTemplate="%s - Bee Solution Partners" 
            defaultTitle="Bee Solution Partners">
        </Helmet>
        <Switch>
        {/* <Route exact path="/test" component={ TestComp } /> */}
        <Route render={({ location }) => (      
            <Fragment>
                <NavBar isTransparent={isTransparent(location.pathname)} />  {/* Navbar */}
                <TransitionGroup>
                    <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={{
                            appear: 300,
                            enter: 300,
                            exit: 0
                        }}
                        >
                        <Switch location={location}>
                            <Route exact path="/" component={ Home } />
                            <Route exact path="/bootcamp" component={ Bootcamp } />
                            <Route exact path="/itConsultant" component={ ITConsultant } />
                            <Route exact path="/products" component={ Products } />
                            <Route exact path="/portfolio" component={ Portfolio } /> 
                            <Route exact path="/aboutUs" component={ AboutUs } />
                            <Route exact path="/contactUs" component={ ContactUs } />
                            
                            {/* <Route exact path="/blog" component={ Blog } /> */}
                            <Route exact path="/career" component={ Career } />                            
                            <Route exact path="/career/apply" component={ ApplyCareer } />
                            <Route component={ NotFound } /> 
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
                <Footer />  {/* Footer */}
            </Fragment>
        )} 
        />
        </Switch>
    </BrowserRouter>
}
