import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import clsx from 'clsx'
import { initAnimation } from '../assets/js/CustomScript'
import { useFetchMeta, useFetchImageHeader } from '../utils/Fetch'
import { makeStyles } from '@material-ui/core'

import ContactUs from '../bootcamp/AsSection'
import { API_URL } from '../utils/Constant'

import BootcampGallery from './Gallery'
import BootcampInstructor from './Instructor'

import '../assets/css/bootcamp.css'

const useStyles = makeStyles(theme => ({
    font: {
        fontSize: '1.5rem'
    },
    icon: {
        color: 'rgb(249, 133, 51) !important',
        fill: 'rgb(249, 133, 51) !important'
    },
    reviewLine: {
        borderTop: '1px solid rgba(200, 200, 200, 1)',
        width: '80%'
    }
}))

export default ({ location: { pathname } }) => {
    const { metaData } = useFetchMeta(pathname)
    const { imgUrl } = useFetchImageHeader('bootcamp_header')
    const classes = useStyles()
    const [review, setReview] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        initAnimation()

        const getReview = async () => {
            return await fetch(API_URL.BOOTCAMP_REVIEW)
                .then(r => r.json())
                // .catch(r => console.log(r))
        }
        setLoading(true)
        getReview().then(r => {
            setLoading(false)
            let { status, data } = r
            if(status) {
                setReview(data)
            }
        })

    }, [])    

    return (
        <Fragment>
            <Helmet>
                <title>Bootcamp</title>
                <meta name="description" content={metaData != null ? metaData.description: ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
            </Helmet>               
            <section className="cid-rwgnEqBGkA mbr-parallax-background" id="header2-54" style={{ backgroundImage: `url(${imgUrl})`}}>
                <div class="mbr-overlay" style={{ opacity: 0.5, backgroundColor: 'rgb(0, 0, 0)'}}></div>
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">BOOTCAMP</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-section article content1 cid-rwD4YL78BY" id="content1-80">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 mbr-fonts-style display-5 col-md-8">
                            <p>
                                <strong>Bee Solution Partners </strong>
                                <span className={classes.font}>
                                    has been a trusted bootcamp resource since 2007. 
                                    We will help you to learn a variety of programming knowledge with a curriculum that follows the latest trend of IT industry.
                                </span>
                            </p>
                            <p>
                                <span className={classes.font}>
                                </span>
                            </p>
                            <p>
                                <span className={classes.font}>
                                    We are constantly adapting our syllabus as coding languages advance, to make sure we give students - including absolute beginners - the most relevant and cutting-edge training possible, while teaching them the best approach to problem solving.
                                </span>
                            </p>
                            <p>
                                <span className={classes.font}>
                                    Our program consists of two sections that will help you to develop your skills, there are:
                                </span>
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features1 cid-rwzk0DMlDH" id="features1-77">
                <div className="container">
                    <div className="media-container-row">
                        <div className="card p-3 col-12 col-md-6">
                            <div className="card-img pb-3">
                                <a href="#content10-78">
                                    <span className={clsx("mbr-iconfont", "mbri-android", classes.icon)}>
                                    </span>
                                </a>
                            </div>
                            <div className="card-box">
                                <h4 className="card-title py-3 mbr-fonts-style display-5">
                                    <a href="#content10-78">Programming</a>
                                </h4>
                            </div>
                        </div>
                        <div className="card p-3 col-12 col-md-6">
                            <div className="card-img pb-3">
                                <a href="#content10-7b">
                                    <span className={clsx("mbr-iconfont", "mbri-edit", classes.icon)}>                                    
                                    </span>
                                </a>
                            </div>
                            <div className="card-box">
                                <h4 className="card-title py-3 mbr-fonts-style display-5">
                                    <a href="#content10-7b">
                                        Software Testing
                                    </a>
                                </h4>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content10 cid-rwzkzHEVSQ" id="content10-78" style={{ backgroundColor: 'white' }}> 
                <div className="container">
                    <div className="inner-container" style={{ width: '66%' }}>
                        <hr className="line" style={{ width: '25%', backgroundColor: 'black'  }} />
                        <div className="section-text align-center mbr-black mbr-fonts-style display-2">
                            Programming
                        </div>
                        <hr className="line" style={{ width: '25%', backgroundColor: 'black' }} />
                    </div>
                </div>
            </section>

            <section className="features11 cid-rwzpKMFq56 cid-padding-content" id="features11-7l">
                <div className="container">
                    <div className="col-md-12">
                        <div className="media-container-row">
                            <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                                <img src="assets/images/bootcamp1-952x716.png" alt="Programming" title="" />
                            </div>
                            <div className=" align-left aside-content">                                
                                <div className="mbr-section-text">
                                    <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                        During our bootcamp, we teach you how successful startups work such as: design your project's wireframe &amp; mockup, test your UX with a prototype, split your user stories across your team and collaborate with branch and pull requests with git and Github.<br/>
                                        At the end of the bootcamp, you will be able to build a web product from scratch, design a relational database and build queries with SQL, integrate a 3rd service using an API or scrape HTML data, and be able to learn a new programming language very quickly, which is extremely important in the fast-paced environment of web development. <br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>          
            </section>

            <section className="features11 cid-rwzpLsQPoE cid-padding-content" id="features11-7m">
                <div className="container">   
                    <div className="col-md-12">
                        <div className="media-container-row">
                            <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                                <img src="assets/images/2bootcamp1-952x952.png" alt="Beginner Programmer" title="" />
                            </div>
                            <div className=" align-left aside-content">
                                <h2 className="mbr-title pt-2 mbr-fonts-style display-2">Beginner Programmer</h2>
                                <div className="mbr-section-text">
                                    <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                        Beginners can learn the basics of object–oriented programming with Python or visual languages like Visual Basic and Scratch. <br />
                                        Start by exploring programming fundamentals, then learn how to code and create an application, and finally understand what’s going on under the hood. Our tutorials dive deep into software engineering specialities like refactoring code, version control, and object-oriented design.<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>          
            </section>

            <section className="features11 cid-rwzquu0q6n cid-padding-content" id="features11-7p">
                <div className="container">   
                    <div className="col-md-12">
                        <div className="media-container-row">
                            <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                                <img src="assets/images/3bootcamp12-952x1015.png" alt="Advance Programmer" title="" />
                            </div>
                            <div className=" align-left aside-content">
                                <h2 className="mbr-title pt-2 mbr-fonts-style display-2">
                                    Advanced Programmer
                                </h2>
                                <div className="mbr-section-text">
                                    <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                        More advanced students can study a variety of topics in C# programming, JavaScript, C++, and more. 
                                        <br/>
                                        We also offer courses in Python, Visual Basic, and C programming.<br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>          
            </section>

            {/* SOFTWARE TESTING */}
            <section className="mbr-section article content10 cid-rwzly3pLgH" id="content10-7b" style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="inner-container" style={{ width: '66%' }}>
                        <hr className="line" style={{ width: '25%', backgroundColor: 'black' }} />
                        <div className="section-text align-center mbr-black mbr-fonts-style display-2">Software Testing</div>
                        <hr className="line" style={{ width: '25%', backgroundColor: 'black' }} />
                    </div>
                </div>
            </section>
            <section className="features11 cid-rwzpMbYPOD cid-padding-content" id="features11-7n">
                <div className="container">   
                    <div className="col-md-12">
                        <div className="media-container-row">
                            <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                                <img src="assets/images/4bootcamp-testing-952x1058.png" alt="Software Testing" title="" />
                            </div>
                            <div className=" align-left aside-content">                                
                                <div className="mbr-section-text">
                                    <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                        Software Testing Bootcamp will help you become more comfortable and confident in testing software applications at just about any level of detail: unit, integration, system, and user acceptance. <br />
                                        You will emerge from this workshop knowing how to develop test cases and test plans. You will also leave with the knowledge of how tools can help you perform testing.
                                        <br />                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>          
            </section>
            <section className="features11 cid-rwDUM5g3xC" id="features11-81">
                <div className="container">   
                    <div className="col-md-12">
                        <div className="media-container-row">
                            <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                                <img src="assets/images/bootcamp12-952x809.png" alt="Mobirise" title="" />
                            </div>
                            <div className=" align-left aside-content">                                
                                <div className="mbr-section-text">
                                    <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                        What you will learn:<br/>
                                        - Basics of Software Testing <br />
                                        - Test case &amp; Bug Report Writing <br />
                                        - Basics on Automation Testing <br/>
                                        - Basics of API Testing <br/>
                                        - Basics of Mobile Testing using Simulators &amp; Emulators<br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>          
            </section>
            
            <BootcampGallery />

            <BootcampInstructor />

        { !loading && review && 
            <section className="testimonials1 cid-rwzmd1TsSt" id="testimonials1-7e">
                <div className="container">
                    <div className="media-container-row">
                        <div className="inner-container" style={{ width: '66%' }}>
                            <hr className="line" style={{ width: '25%', backgroundColor: 'black', height: 2 }} />
                                <div className="section-text align-center mbr-black mbr-fonts-style display-2">Reviews</div>
                            <hr className="line" style={{ width: '25%', backgroundColor: 'black', height: 2 }} />
                        </div>
                    </div>
                </div>
                <div className="container pt-3 mt-2">
                    <div className="media-container-row">
                    { review.map((fe, index) => {
                        return (
                            <div className="mbr-testimonial p-3 align-center col-12 col-md-6 col-lg-4" key={index}>
                                <div className="panel-item p-3" style={{ minHeight: 300 }}>
                                    <div className="card-block">
                                        <p className="mbr-text mbr-fonts-style display-7" style={{ fontStyle: 'italic' }}>
                                            {`"${fe.review}"`}                                                
                                        </p>
                                    </div>
                                    <div className="card-footer">
                                        <div className="mbr-author-name mbr-bold mbr-fonts-style display-7">
                                            <hr className={classes.reviewLine}/>
                                            {fe.name}
                                        </div>
                                        <small className="mbr-author-desc mbr-italic mbr-light mbr-fonts-style display-7">
                                            {fe.title}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                </div>   
            </section>
        }
            <ContactUs />
        </Fragment>
    )
}