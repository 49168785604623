import React, { Fragment, useEffect } from 'react'
import Helmet from 'react-helmet'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useFetchMeta, useFetchImageHeader } from '../utils/Fetch'
import ContactUs from './AsSection'
import { initAnimation } from '../assets/js/CustomScript'

const useStyles = makeStyles(theme => ({
    font: {
        fontSize: '1.5rem'
    },
    icon: {
        color: 'rgb(249, 133, 51) !important',
        fill: 'rgb(249, 133, 51) !important'
    },
    line: {
        borderTop: '5px solid rgba(0,0,0, .4)'
    },
    icon: { 
        color: 'rgb(249, 133, 51) !important', 
        fill: 'rgb(249, 133, 51) !important', 
        fontSize: 80 
    },
    title: {
        fontWeight: 10,
        textAlign: 'center',
        fontSize: 50 ,
        marginBottom: 40
    }
}))

export default ({ location: { pathname } }) => {
    const { metaData } = useFetchMeta(pathname)
    const { imgUrl } = useFetchImageHeader('itconsultant_header')
    const classes = useStyles()

    useEffect(() => {
        initAnimation()
    }, [])

    return (
    <Fragment>
        <Helmet>
            <title>IT Consultant</title>
            <meta name="description" content={metaData != null ? metaData.description: ''} />
            <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
        </Helmet> 
        <section className="cid-rwB7qUNQ30 mbr-parallax-background" id="header2-7z" style={{ backgroundImage: `url(${imgUrl})`}}>
            <div class="mbr-overlay" style={{ opacity: 0.5, backgroundColor: 'rgb(0, 0, 0)'}}></div>
            <div className="container align-center">
                <div className="row justify-content-md-center">
                    <div className="mbr-white col-md-10">
                        <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">IT CONSULTANT</h1>
                    </div>
                </div>
            </div>
        </section>

        <section className="mbr-section content4 cid-rwgoCPtGrc" id="content4-59" style={{paddingBottom: 0}}>
            <div className="container">
                <div className="media-container-row">
                    <div className="title col-12 col-md-10">
                        <h3 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
                            <br/>
                            <strong>Bee Solution Partners </strong>helps you make confident technology decisions and ensure your IT organization is agile, effective and equipped to cut through the buzz of the latest trends to create enduring results. 
                            We provide custom software solutions to perfectly suit every types of your business needs.
                            <div><br/></div>
                            <div>
                            Below are all the areas we are experts in, areas where we collaborate with our clients and help them build great products based on their needs.
                            </div>
                            <hr className="line"/>
                        </h3>
                    </div>
                </div>
            </div>
        </section>

        <section className="features1 cid-rwtie1riu4" id="features1-6a">
            <div className="container">
                <div className="media-container-row">

                    <div className="card p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-3">
                            <a href="#features11-6b">
                                <span className={clsx("mbr-iconfont", "mbri-browse", classes.icon)}>
                                </span>
                            </a>
                        </div>
                        <div className="card-box">
                            <h4 className="card-title py-3 mbr-fonts-style display-5">
                                <a href="#features11-6b">
                                    Web Development
                                </a>
                            </h4>
                        </div>
                    </div>

                    <div className="card p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-3">
                            <a href="#features11-6d">
                                <span className={clsx("mbr-iconfont", "mbri-touch", classes.icon)}>
                                </span>
                            </a>
                        </div>
                        <div className="card-box">
                            <h4 className="card-title py-3 mbr-fonts-style display-5">
                                <a href="#features11-6d">
                                    Mobile Development
                                </a>
                            </h4>                            
                        </div>
                    </div>

                    <div className="card p-3 col-12 col-md-6 col-lg-3">
                        <div className="card-img pb-3">
                            <a href="#features11-6e">
                                <span className={clsx("mbr-iconfont", "mbri-desktop", classes.icon)}>
                                </span>
                            </a>
                        </div>
                        <div className="card-box">
                            <h4 className="card-title py-3 mbr-fonts-style display-5">
                                <a href="#features11-6e">
                                    Desktop Development
                                </a>
                            </h4>                            
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="features11 cid-rwtja9m1Bx" id="features11-6b">
            <div className="container">   
                <div className="col-md-12">
                    <div className="media-container-row">
                        <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                            <img src="assets/images/asset-front-ed-6-952x792.png" alt="Front End" title="" />                            
                        </div>
                        <div className=" align-left aside-content">
                            <h2 className="mbr-title pt-2 mbr-fonts-style display-2">
                                Web Development
                            </h2>
                            <div className="mbr-section-text">
                                <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                    We build cross platform desktop apps (Windows and Linux) for all your business needs with JavaScript, HTML &amp; CSS.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>          
        </section>

        <section className="features11 cid-rwtk59lMon" id="features11-6d">
            <div className="container">   
                <div className="col-md-12">
                    <div className="media-container-row">
                        <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                            <img src="assets/images/asset-front-ed-4-952x688.png" alt="Mobirise" title="" />
                        </div>
                        <div className=" align-left aside-content">
                            <h2 className="mbr-title pt-2 mbr-fonts-style display-2">
                                Mobile Development
                            </h2>
                            <div className="mbr-section-text">
                                <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                    With us you can create the mobile applications of your own and give you a great experience in exploring the technology through your hands. 
                                    <br />There are many mobile applications you can rely on for your daily and business activities, such as mobile version of your website.
                                    <br />Tell us your plan and we'll make it happen.
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>          
        </section>

        <section className="features11 cid-rwtl1CnYAA" id="features11-6e">
            <div className="container">   
                <div className="col-md-12">
                    <div className="media-container-row">
                        <div className="mbr-figure m-auto" style={{ width: '50%' }}>
                            <img src="assets/images/asset-front-ed222-458x359.png" alt="Desktop Development" title="" />                            
                        </div>
                        <div className=" align-left aside-content">
                            <h2 className="mbr-title pt-2 mbr-fonts-style display-2">
                                Desktop Development
                            </h2>
                            <div className="mbr-section-text">
                                <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5">
                                We can create groundbreaking desktop solutions from scratch, modernize your legacy solutions, create custom middleware for connectivity of your enterprise applications thus making your business process agile and effective.
                                <br />We covered some of the most important decisions in an application’s architecture but there are other considerations like Localization, Testing, User Settings, Database and so on.
                                <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>          
        </section>

        <section className="mbr-section article content10 cid-rwxjJ57ewW" id="content10-73" style={{ backgroundColor: 'white' }}>
            <div className="container">
                <div className="inner-container" style={{ width: '66%' }}>
                    <hr className="line" style={{ width: '25%', backgroundColor: 'black' }} />
                    <div className="section-text align-center mbr-black mbr-fonts-style display-2">
                            Business Offer
                        </div>
                    <hr className="line" style={{ width: '25%', backgroundColor: 'black'  }} />
                </div>
            </div>
        </section>

        <section className="mbr-section info3 cid-rwxkkCZjAw" id="info3-74">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="media-container-column title col-12 col-md-10">
                        <h3 className="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5">
                        In order to assist and serve your needs in custom software development we offer a business which could be options for you depend on your needs and here's the process flow as described below.
                        </h3>
                    </div>
                </div>
            </div>
        </section>

        <section className="mbr-section content6 cid-rwtnMrsF1a" id="content6-6j">
            <div className="container">
                <div className="media-container-row">
                    <div className="col-12 col-md-8">
                        <div className="media-container-row">
                            <div className="mbr-figure" style={{ width: '60%' }}>
                                <img src="assets/images/flow1-thought1-436x436.png" alt="Have a thought" title="" />
                            </div>
                            <div className="media-content">
                                <div className="mbr-section-text">
                                    <p className="mb-0 mbr-fonts-style display-5">
                                        <strong>Have a Thought</strong>
                                        <br />
                                        Tell us your idea or plan and what are you going to achieve, then we will come up with solutions and suggestions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="mbr-section content7 cid-rwtnO7FLhG" id="content7-6k">
            <div className="container">
                <div className="media-container-row">
                    <div className="col-12 col-md-8">
                        <div className="media-container-row">
                            <div className="media-content">
                                <div className="mbr-section-text">
                                    <p className="align-right mb-0 mbr-fonts-style display-5">
                                        <strong>Consult With Us</strong>
                                        <br />
                                        Let's discuss about the outline of your project and we will give solutions about your products.
                                    </p>
                                </div>
                            </div>
                            <div className="mbr-figure" style={{ width: '60%' }}>
                                <img src="assets/images/flow1-talk-with-us2-436x436.png" alt="Consult with us" title="" />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="mbr-section content6 cid-rwtnQsRi6T" id="content6-6l">
            <div className="container">
                <div className="media-container-row">
                    <div className="col-12 col-md-8">
                        <div className="media-container-row">
                            <div className="mbr-figure" style={{ width: '60%' }}>
                                <img src="assets/images/flow1-3proposal-436x436.png" alt="Proposal Offer" title="" />  
                            </div>
                            <div className="media-content">
                                <div className="mbr-section-text">
                                    <p className="mb-0 mbr-fonts-style display-5">
                                        <strong>We Offer Proposal</strong>
                                        <br />
                                        Once we've got the project's details from you then we will send software development proposal about the price and the estimate time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="mbr-section content7 cid-rwtnR7GHyS" id="content7-6m">
            <div className="container">
                <div className="media-container-row">
                    <div className="col-12 col-md-8">
                        <div className="media-container-row">
                            <div className="media-content">
                                <div className="mbr-section-text">
                                    <p className="align-right mb-0 mbr-fonts-style display-5">
                                        <strong>Dealing</strong>
                                        <br />
                                        We will send you our final proposal, quotation and MOU to be agreed by both parties and give you access to our project consultant for your quick access (chat, call, video call, etc).
                                    </p>
                                </div>
                            </div>
                            <div className="mbr-figure" style={{ width: '60%' }}>
                                <img src="assets/images/flow1-4dealing2-436x436.png" alt="Dealing" title="" />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="mbr-section info3 cid-rwxkkCZjAw" id="info3-74">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="media-container-column title col-12 col-md-10">
                        <h3 className="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5">
                        After the dealing process is set, you can choose whether the project is done by onsite or offsite regarding to your project and then start the development process right away.
                        </h3>
                    </div>
                </div>
            </div>
        </section>

        <section className="features11 cid-rwtja9m1Bx" id="features11-6b">
            <div className="container">   
                <div className="col-md-12">
                    <div className="media-container-row">
                        <div className="col-md-6">
                            <div className="card-text">
                                <h3 className={classes.title}>
                                    Onsite
                                </h3>
                            </div>
                            <div className="mbr-figure m-auto">
                                <img src="assets/images/onsite.png" style={{ height: 377 }} alt="Onsite" title="" />                            
                            </div>
                            <div className="card-text">
                                <p className="mbr-text align-right mb-0 mbr-fonts-style display-5">
                                On-site support refers to technical assistance that is offered at your premises. This support is usually needed when off-site support cannot be given, or the user is a complete novice. Compared to off-site support, on-site support takes longer and is usually more expensive.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card-text">
                                <h3 className={classes.title}>
                                    Offsite
                                </h3>
                            </div>
                            <div className="mbr-figure m-auto">
                                <img src="assets/images/offsite.png" alt="Offsite" style={{ height: 377 }} title="" />                            
                            </div>
                            <div className="card-text">
                                <p className="mbr-text align-right mb-0 mbr-fonts-style display-5">
                                This type of support  referred to as remote or online support. It usually includes video, live chats, email or telephone support.  With off-site support, we may remotely connect to a computer or network to help with problems.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>          
        </section>

        <ContactUs />
    </Fragment>
    )
}