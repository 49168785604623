/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import DateFnsUtils from '@date-io/date-fns'

import { makeStyles } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress  from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { Alert, AlertTitle }  from '@material-ui/lab'

import AttachmentIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Close'

import { useFetchMeta, useFetchImageHeader } from '../utils/Fetch'
import { initAnimation } from '../assets/js/CustomScript'
import { API_URL } from '../utils/Constant'

import '../assets/css/career.css'

const mytheme = createMuiTheme({
    palette: {
      primary: {
        main: '#a2d729',
      },
    },
})

const useStyles = makeStyles(theme => ({
    font: {
        fontSize: '1.5rem'
    },
    icon: {
        color: 'rgb(249, 133, 51) !important',
        fill: 'rgb(249, 133, 51) !important'
    },
    reviewLine: {
        borderTop: '1px solid rgba(200, 200, 200, 1)',
        width: '80%'
    },
    container: {
        paddingLeft: 48,
        paddingRight: 48,
        // eslint-disable-next-line no-useless-computed-key
        // ['@media (max-width: 360px)']: {width: 540},
        // // eslint-disable-next-line no-useless-computed-key
        // ['@media (min-width: 768x)']: { maxWidth: 720 },
        // // eslint-disable-next-line no-useless-computed-key
        // ['@media (min-width: 992x)']: { maxWidth: 960 },
        // // eslint-disable-next-line no-useless-computed-key
        // ['@media (min-width: 1200px)']: { maxWidth: 1140 },
    },
    inputLabel: {
        fontSize: 18,
        color: '#232323',
    },
    inputItem: {
        marginBottom: 16,
    },
    button: {
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '1rem',
        fontFamily: '"Rubik", sans-serif',
        margin: '.4rem .8rem',
        padding: '1rem 3rem',
        textTransform: 'capitalize',
        '&:hover': {
            textDecoration: 'none',
            color: 'unset'
        },
    },
    buttonBack: {
        backgroundColor: '#eaeaea',
        borderRadius: 100,
        '&:hover': {
            backgroundColor: '#eaeaea',
        },
    },
    buttonSubmit: {
        backgroundColor: '#a2d729',
        borderRadius: 100,
        '&:hover': {
            backgroundColor: '#a2d729',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    controlForm: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 559px)']: {display: 'block', width: '100%'},
        // eslint-disable-next-line no-useless-computed-key
        ['@media (width: 560px)']: {display: 'block', width: '100%'},
    },
    form: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 559px)']: {width: '250%'},
        // eslint-disable-next-line no-useless-computed-key
        ['@media (width: 560px)']: {width: '220%'},
         // eslint-disable-next-line no-useless-computed-key
        ['@media (min-width: 768x)']: {maxWidth: 720},
         // eslint-disable-next-line no-useless-computed-key
        ['@media (min-width: 992x)']: { maxWidth: 960 },
         // eslint-disable-next-line no-useless-computed-key
        ['@media (min-width: 1200px)']: {maxWidth: 1140},
      
    },
    buttonSubmitBack: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 414px)']: {justifyContent: 'center'},
    }
}))

const initFormData = {
    name: '',
    birthday: '',
    ktp: '',
    phone: '',
    email: '',
    address: '',
    university: '',
    major: '',
    year: '',
    gpa: '',
    toefl: '',
    graduated: '',
    experience: '',
    cv: '',
}

const initFormHelper = {
    name: { error: false, message: null },
    birthday: { error: false, message: null },
    ktp: { error: false, message: null },
    phone: { error: false, message: null },
    email: { error: false, message: null },
    address: { error: false, message: null },
    university: { error: false, message: null },
    major: { error: false, message: null },
    year: { error: false, message: null },
    gpa: { error: false, message: null },
    toefl: { error: false, message: null },
    graduated: { error: false, message: null },
    experience: { error: false, message: null },
    cv: { error: false, message: null },
}

const isEmpty = value => {
    if (typeof value === 'string' || value instanceof String) {
        return value.trim().length == 0
    }
    return value === '' || value == null || typeof value == 'undefined'
}

const isEmail = value => {
    const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
    return emailRegex.test(String(value).toLocaleLowerCase())
}

const isPhone = value => {
    var phoneno = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
    return phoneno.test(value)
}

const isKTP = value => {
    var noKTP = /^[0-9]+$/gm
    return noKTP.test(value)
}

const maxLength = (value, length) => {
    if (typeof value === 'string' || value instanceof String) {
        return value.length <= length
    }
    return true
}

export default ({ location: { pathname } }) => {
    const urlQuery = new URLSearchParams(window.location.search)
    const jobPosition = urlQuery.get("position") || null
    const jobEmployment = urlQuery.get("employment") || null

    const classes = useStyles()
    const { metaData } = useFetchMeta(pathname)
    const { imgUrl } = useFetchImageHeader('careerapply_header')

    const [loading, setLoading] = useState(false)
    const [comboGraduated, setComboGraduated] = useState([])
    const [formMessage, setFormMessage] = useState({
        show: false,
        severity: null,
        title: null,
        message: null,
    })
    const [formBirthday, setFormBirthday] = useState(null)
    const [formData, setFormData] = useState(initFormData)
    const [formFile, setFormFile] = useState(null)
    const [formHelper, setFormHelper] = useState(initFormHelper)
    const formRules = {
        name: [ 
            v => !isEmpty(v) || 'Full Name should not be empty',
            v => maxLength(v, 200) || 'Please input <= 200',
        ],
        birthday: [ v => !isEmpty(v) || 'Date of Birth should not be empty' ],
        ktp: [
            v => !isEmpty(v) || 'ID (KTP) should not be empty',
            v => maxLength(v, 20) || 'Please input <= 20',
            v => isKTP(v) || 'Please fill out with correct format'
        ],
        phone: [
            v => !isEmpty(v) || 'Phone Number should not be empty',
            v => maxLength(v, 20) || 'Please input <= 20',
            v => isPhone(v) || 'Please fill out with correct format'
        ],
        email: [
            v => !isEmpty(v) || 'Email should not be empty',
            v => isEmail(v) || 'Please fill out with correct format',
            v => maxLength(v, 100) || 'Please input <= 100',
        ],
        address: [
            v => !isEmpty(v) || 'Address should not be empty',
            v => maxLength(v, 255) || 'Please input <= 255',
        ],
        university: [
            v => !isEmpty(v) || 'University should not be empty',
            v => maxLength(v, 100) || 'Please input <= 255',
        ],
        major: [
            v => !isEmpty(v) || 'Major should not be empty' ,
            v => maxLength(v, 100) || 'Please input <= 100',
        ],
        year: [
            v => !isEmpty(v) || 'Year of Graduation should not be empty',
            v => parseInt(v) <= new Date().getFullYear() || `Please fill out <= ${new Date().getFullYear()}`,
        ],
        gpa: [
            v => !isEmpty(v) || 'IPK/Nilai should not be empty',
            v => parseFloat(v) > 0 || 'Please fill out > 0',
        ],
        toefl: [
            v => !isEmpty(v) || 'TOEFL should not be empty',
            v => parseInt(v) > 0 || 'Please fill out > 0',
        ],
        graduated: [ v => !isEmpty(v) || 'Graduted should not be empty' ],
        experience: [ v => !isEmpty(v) || 'Working Experiences should not be empty' ],
        cv: [ 
            file => !isEmpty(file) || 'CV Resume should not be empty',
            file => file.size / (1024 * 1024) <= 2 || 'Please attach file with size <= 2 MB',
        ]
    }

    const validate = (name, value) => {
        if (formRules[name]) {
            for (const rule of formRules[name]) {
                const result = rule(value)
                if (result !== true) {
                    return { error: true, message: result }
                }
            }
            return { error: false, message: null }
        } else {
            return null
        }
    }

    const handleInputInteger = event => {
        event = event || window.event
        const charCode = event.which ? event.which : event.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault()
        } else {
          return true
        }
    }

    const handleInputDecimal = event => {
        const { value } = event.target
        event = event || window.event
        const charCode = event.which ? event.which : event.keyCode
        if (charCode == 46) {
            if (value && value.indexOf('.') > -1) {
                event.preventDefault()
            } else {
                return true
            }
        } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault()
        } else {
          return true
        }
    }

    const handleInputDate = date => {
        setFormBirthday(date)
        if (date) {
            const y = date.getFullYear()
            const m = date.getMonth() + 1
            const d = date.getDate()
            handleInputChange({
                target: {
                    name: 'birthday',
                    value: `${y}-${m + 1 < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`,
                }
            })
        } else {
            handleClearDate()
        }
    }

    const handleClearDate = () => {
        setFormBirthday(null)
        handleInputChange({
            target: {
                name: 'birthday',
                value: null
            }
        })
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setFormData(state => ({ ...state, [name]: value }))

        const result = validate(name, value)
        if (result) {
            setFormHelper(state => ({ ...state, [name]: result }))
        }
    }

    const handleInputFile = event => {
        const { files } = event.target
        if (files.length > 0) {
            setFormData(state => ({ ...state, cv: files[0].name }))
            setFormFile(files[0])
            const result = validate('cv', files[0])
            if (result) {
                setFormHelper(state => ({ ...state, cv: result }))
            }
        } else {
            handleClearFile()
        }
    }

    const handleClearFile = () => {
        setFormFile(null)
        setFormData(state => ({ ...state, cv: ''}))
        setFormHelper(state => ({
            ...state, 
            cv: {
                error: true,
                message: 'CV Resume should not be empty'
            }
        }))
    }

    const handleMessageClose = () => {
        setFormMessage({
            show: false,
            severity: 'info',
            title: '',
            message: '',
        })
    }

    const handleValidate = () => {
        let valid = true
        let infos = {}
        for (const field in formData) {
            let result
            if (field == 'cv') {
                result = validate(field, formFile)
            } else {
                result = validate(field, formData[field])
            }
            if (result) {
                infos[field] = result
                if (result.error) {
                    valid = false
                }
            }
        }

        setFormHelper(infos)
        return valid
    }

    const handleSubmit = () => {
        if (!handleValidate()) return

        setLoading(true)
        const form = new FormData()
        form.append('name', formData.name)
        form.append('birthday', formData.birthday)
        form.append('ktp', formData.ktp)
        form.append('phone', formData.phone)
        form.append('email', formData.email)
        form.append('address', formData.address)
        form.append('university', formData.university)
        form.append('major', formData.major)
        form.append('year', formData.year)
        form.append('gpa', formData.gpa)
        form.append('toefl', formData.toefl)
        form.append('graduated', formData.graduated)
        form.append('experience', formData.experience)
        form.append('position', `${jobPosition} ${jobEmployment}`)
        form.append('file', formFile)

        fetch(`${API_URL.APPLY_CAREER}`, {
            method: 'POST',
            body: form,
        })
            .then(resp => resp.json())
            .then(body => {
                if (body.status) {
                    setFormData(initFormData)
                    setFormHelper(initFormHelper)
                    setFormBirthday(null)
                    setFormFile(null)
                    setFormMessage({
                        show: true,
                        severity: 'success',
                        title: 'Job Application sent successfully',
                        message: 'Your job application has successfully sent. We will review and contact you if you are selected to continue to the next process. Thank You for Applying.',
                    })
                } else {
                    console.error('[submit-application]', body)
                    setFormMessage({
                        show: true,
                        severity: 'error',
                        title: 'Job Application failed to send',
                        message: 'Your job application has failed to sent. Please contact web administrator. Sorry for the trouble.',
                    })
                }
                setLoading(false)
            })
            .catch(err => {
                console.error('[submit-application]', err)
                setFormMessage({
                    show: true,
                    severity: 'error',
                    title: 'Job Application failed to send',
                    message: 'Your job application has failed to sent. Please contact web administrator. Thank You for Applying.',
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        initAnimation()

        // loading combo graduated
        setLoading(true)
        fetch(`${API_URL.COMBO}?op=career-apply-graduated`)
            .then(resp => resp.json())
            .then(body => {
                if (body.status) {
                    setComboGraduated(body.data)
                } else {
                    setComboGraduated([
                        { value: 'static_smk_sma', label: 'SMK / SMA' },
                        { value: 'static_diploma', label: 'Diploma' },
                        { value: 'static_bachelor', label: 'Bachelor' },
                        { value: 'static_master', label: 'Master' },
                        { value: 'static_doctoral', label: 'Doctoral' },
                    ])
                }
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
            })
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>Career Apply</title>
                <meta name="description" content={metaData != null ? metaData.description : ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords : ''} />
            </Helmet>
            <section className="cid-rwgnEqBGkA mbr-parallax-background" id="header2-54" style={{ backgroundImage: `url(${imgUrl})`}}>
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">CAREER</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cid-career mt-4">
                <div className="container align-center">
                    <div className="justify-content-md-center">
                        <div className="mbr-text mbr-fonts-style">
                            <span className="display-5">
                                {jobPosition
                                  ? <strong>Job Application for {jobPosition} {jobEmployment}</strong>
                                  : <strong>Job Application</strong>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cid-career my-4">
                <MuiThemeProvider theme={mytheme}>
                <Container className={classes.container}>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={3} className={classes.controlForm}>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={formMessage.show} onClose={handleMessageClose}
                        >
                            <Alert onClose={handleMessageClose} severity={formMessage.severity}>
                                <AlertTitle><strong>{formMessage.title}</strong></AlertTitle>
                                {formMessage.message}
                            </Alert>
                        </Snackbar>
                        {/* personal information */}
                            <Grid item xs={6} >
                            <Grid>
                                {/* full name */}
                                <Grid item xs={12} className={`${classes.inputItem} control-form`} >
                                    <InputLabel
                                        htmlFor="form-name" className={classes.inputLabel}
                                        required error={formHelper.name.error}
                                    >
                                        Full Name
                                    </InputLabel>
                                    <TextField
                                        id="form-name" type="text" placeholder="Full Name"
                                        name="name" value={formData.name} onChange={handleInputChange}
                                        error={formHelper.name.error}
                                        required fullWidth variant="outlined"
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.name.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.name.length}/200</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* birthday */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-birthday" className={classes.inputLabel}
                                        required error={formHelper.birthday.error}
                                    >
                                        Date of Birth
                                    </InputLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            disableFuture
                                            autoOk
                                            fullWidth
                                            variant="inline" inputVariant="outlined"
                                            openTo="year"
                                            placeholder="DD-MMM-YYYY"
                                            format="dd-MMM-yyyy"
                                            views={["year", "month", "date"]}
                                            initialFocusedDate={new Date(2000, 0, 1)}
                                            value={formBirthday}
                                            onChange={handleInputDate}
                                            error={formHelper.birthday.error}
                                            helperText={formHelper.birthday.message || ' '}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: formBirthday &&
                                                    <InputAdornment position="end">
                                                        <IconButton variant="contained" color="default" component="span" onClick={handleClearDate}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            className={classes.form}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {/* ktp */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-ktp" className={classes.inputLabel}
                                        required error={formHelper.ktp.error}
                                    >
                                        ID (KTP)
                                    </InputLabel>
                                    <TextField
                                        id="form-ktp" type="text" placeholder="ID (KTP)"
                                        required fullWidth variant="outlined"
                                        name="ktp" value={formData.ktp} onChange={handleInputChange}
                                        error={formHelper.ktp.error}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.ktp.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.ktp.length}/20</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* phone number */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-phone" className={classes.inputLabel}
                                        required error={formHelper.phone.error}
                                    >
                                        Phone Number
                                    </InputLabel>
                                    <TextField
                                        id="form-phone" type="text" placeholder="Phone Number"
                                        name="phone" value={formData.phone} onChange={handleInputChange}
                                        required fullWidth variant="outlined"
                                        error={formHelper.phone.error}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.phone.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.phone.length}/20</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* email */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-email" className={classes.inputLabel}
                                        required error={formHelper.email.error}
                                    >
                                        Email
                                    </InputLabel>
                                    <TextField
                                        id="form-email" type="text" placeholder="e.g. email@gmail.com"
                                        required fullWidth variant="outlined"
                                        name="email" value={formData.email} onChange={handleInputChange}
                                        error={formHelper.email.error}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.email.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.email.length}/100</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* address */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-address" className={classes.inputLabel}
                                        required error={formHelper.address.error}
                                    >
                                        Address
                                    </InputLabel>
                                    <TextField
                                        id="form-address" type="text" placeholder="Address"
                                        required fullWidth variant="outlined"
                                        multiline rows={10}
                                        name="address" value={formData.address} onChange={handleInputChange}
                                        error={formHelper.address.error}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.address.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.address.length}/255</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* education information */}
                        <Grid item xs={6}>
                            <Grid container>
                                {/* university */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-university" className={classes.inputLabel}
                                        required error={formHelper.university.error}
                                    >
                                        University / School
                                    </InputLabel>
                                    <TextField
                                        id="form-university" type="text" placeholder="University / School"
                                        required fullWidth variant="outlined"
                                        name="university" value={formData.university} onChange={handleInputChange}
                                        error={formHelper.university.error}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.university.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.university.length}/100</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* major */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-major" className={classes.inputLabel}
                                        required error={formHelper.major.error}
                                    >
                                        Major
                                    </InputLabel>
                                    <TextField
                                        id="form-major" type="text" placeholder="Major"
                                        required fullWidth variant="outlined"
                                        name="major" value={formData.major} onChange={handleInputChange}
                                        error={formHelper.major.error}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Grid container>
                                                <Grid item xs={10}>{formHelper.major.message}</Grid>
                                                <Grid item xs={2} align="right">{formData.major.length}/100</Grid>
                                            </Grid>
                                        }
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* year */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-year" className={classes.inputLabel}
                                        required error={formHelper.year.error}
                                    >
                                        Year of Graduation
                                    </InputLabel>
                                    <TextField
                                        id="form-year" type="text" placeholder="YYYY"
                                        required fullWidth variant="outlined"
                                        name="year" value={formData.year} onChange={handleInputChange}
                                        error={formHelper.year.error}
                                        helperText={formHelper.year.message || ' '}
                                        onKeyPress={handleInputInteger}
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* gpa */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-gpa" className={classes.inputLabel}
                                        required error={formHelper.gpa.error}
                                    >
                                        IPK / Nilai
                                    </InputLabel>
                                    <TextField
                                        id="form-gpa" type="text" placeholder="e.g. X.XX"
                                        required fullWidth variant="outlined"
                                        name="gpa" value={formData.gpa} onChange={handleInputChange}
                                        error={formHelper.gpa.error}
                                        helperText={formHelper.gpa.message || ' '}
                                        onKeyPress={handleInputDecimal}
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* toefl */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-toefl" className={classes.inputLabel}
                                        required error={formHelper.toefl.error}
                                    >
                                        TOEFL
                                    </InputLabel>
                                    <TextField
                                        id="form-toefl" type="text" placeholder="e.g. XXX"
                                        required fullWidth variant="outlined"
                                        name="toefl" value={formData.toefl} onChange={handleInputChange}
                                        error={formHelper.toefl.error}
                                        helperText={formHelper.toefl.message  || ' '}
                                        onKeyPress={handleInputInteger}
                                        className={classes.form}
                                    />
                                </Grid>
                                {/* cv / resume */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-cv" className={classes.inputLabel}
                                        required error={formHelper.cv.error}
                                    >
                                        CV Resume
                                    </InputLabel>
                                    <TextField
                                        type="text" placeholder="Max Size 2MB"
                                        value={formData.cv}
                                        error={formHelper.cv.error}
                                        helperText={formHelper.cv.message || ' '}
                                        required fullWidth variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment:
                                                <InputAdornment position="end">
                                                {formData.cv
                                                  ? <IconButton variant="contained" color="default" component="span" onClick={handleClearFile}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                  : <label htmlFor="form-cv" style={{ marginTop: 8 }}>
                                                        <Button variant="contained" color="primary" component="span">
                                                            <AttachmentIcon />
                                                        </Button>
                                                    </label>
                                                }
                                                </InputAdornment>
                                            }}
                                        className={classes.form}
                                    />
                                    <input
                                        id="form-cv" type="file" hidden
                                        name="cv" onChange={handleInputFile}
                                    />
                                </Grid>
                                {/* graduated */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        htmlFor="form-graduated" className={classes.inputLabel}
                                        required error={formHelper.graduated.error}
                                    >
                                        Graduated
                                    </InputLabel>
                                    <FormControl
                                        required error={formHelper.graduated.error}
                                        variant="outlined" fullWidth
                                    >
                                        <Select
                                            id="form-graduated"
                                            name="graduated" value={formData.graduated} onChange={handleInputChange} className={classes.form}
                                            displayEmpty fullWidth
                                        >
                                            <MenuItem value=""><em>- Select -</em></MenuItem>
                                            {comboGraduated.map((item, i) => {
                                                return <MenuItem value={item.value} key={i}>{item.label}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText>{formHelper.graduated.message}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                {/* experience */}
                                <Grid item xs={12} className={classes.inputItem}>
                                    <InputLabel
                                        className={classes.inputLabel}
                                        required error={formHelper.experience.error}
                                    >
                                        Working Experience
                                    </InputLabel>
                                    <FormControl required error={formHelper.experience.error}>
                                        <RadioGroup row aria-label="experience" name="experience" value={formData.experience} onChange={handleInputChange}>
                                            <FormControlLabel value="0-1" control={<Radio color="primary" />} label="0-1" />
                                            <FormControlLabel value="1-3" control={<Radio color="primary" />} label="1-3" />
                                            <FormControlLabel value="3-5" control={<Radio color="primary" />} label="3-5" />
                                            <FormControlLabel value=">5" control={<Radio color="primary" />} label=">5" />
                                        </RadioGroup>
                                        <FormHelperText>{formHelper.experience.message || ' '}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="flex-end" className={classes.buttonSubmitBack}>
                                <Button
                                    component={Link} href="/career"
                                    className={`${classes.button} ${classes.buttonBack}`}
                                    variant="contained" disableElevation
                                >
                                    Back
                                </Button>
                                <Button
                                    className={`${classes.button} ${classes.buttonSubmit}`}
                                    variant="contained" disableElevation
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                </MuiThemeProvider>
            </section>
        </Fragment>
    )
}
