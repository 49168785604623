import React, { Fragment, useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core'
// import Fade from '@material-ui/core/Fade'
// import CircularProgress from '@material-ui/core/CircularProgress'
import { API_URL, STATIC_URL } from '../utils/Constant'
// import { initClientPlugin, unsubClientPlugin } from '../assets/js/CustomScript'

import '../assets/css/home.css'
import SectionTitle from '../common/SectionTitle'
import ImgGalleryDialog from '../common/ImgGalleryDialog'

// const useStyles = makeStyles(theme => ({
//     loading: {
//         color: '#a2d729',        
//     },
// }))

export default () => {
    // const classes = useStyles()
    const [clients, setClients] = useState([])
    // const [loading, setLoading] = useState(false)
    const [openGallery, setOpenGallery] = useState(false)
    const [selectedValue, setSelectedValue] = useState(0)
    
    useEffect(() => {
        // setLoading(true)
        const fetchClient = async () => {
            return await fetch(API_URL.CLIENTS)
                .then(resp => resp.json())
                .catch((error) => {
                    console.log("home", error.message)
                    return { status: false }
                })
        }
        fetchClient().then(({ status, data }) => { 
            // setLoading(false)
            if(status){
                setClients(data)
                // setTimeout(() => {
                //     initClientPlugin()                    
                // }, 500); 
            }
        })
    }, [])

    const handleClick = (id) => () => {
        setSelectedValue(id)
        setOpenGallery(true)
    }

    const handleClose = () => {
        setOpenGallery(false)
        setSelectedValue(0)
    }

    return (
        <Fragment>
            <section className="mbr-section article content10 cid-rys5wxOGGv" id="content10-9o">
                <SectionTitle title="Our Clients" />                
            </section>
            <section className="mbr-gallery mbr-slider-carousel cid-ryrt7UAZ4N" id="gallery3-9n">
                <div className="container">
                    <div>
                        <div >
                            <div className="mbr-gallery-layout-default">                                
                                <div style={{ textAlign: 'center' }}>
                                    { clients.map((cl, id) => {
                                        return ( 
                                            <div className="mbr-gallery-item mbr-gallery-item--p3" data-video-url="false" data-tags="Awesome" key={id}>
                                                <div onClick={handleClick(id)}>
                                                    <img src={STATIC_URL + cl.path} alt="" title="" className="img-responsive clients-img"/>
                                                    <span className="icon-focus"></span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="clearfix">
                                </div>
                            </div>
                        </div>
                        <ImgGalleryDialog onClose={handleClose} open={openGallery} selectedValue={selectedValue} imgList={clients.map(m => STATIC_URL + m.path)} />
                    </div>
                </div>
            </section>            
        </Fragment>
    )
}