export const ENV = () => {
    return process.env.NODE_ENV    
}

export const BASE_API_URL = ENV() === 'production' ? 'https://api-comprof.ptbsp.com' : 'http://localhost:8001';
export const STATIC_URL = BASE_API_URL + '/static';
export const API_URL = {
    AUTH: BASE_API_URL + '/api/auth',
    BANNER: BASE_API_URL + '/api/banner',
    SYSTEM: BASE_API_URL + '/api/system',
    COMPANY_INFO: BASE_API_URL + '/api/companyinfo',
    PAGE_META: BASE_API_URL + '/api/pagemetainfo',
    CONTACT_US: BASE_API_URL + '/api/post-contactus',
    CLIENTS: BASE_API_URL + '/api/companyclients',
    TESTIMONIES: BASE_API_URL + '/api/companytestimony',
    BOOTCAMP_REVIEW: BASE_API_URL + '/api/bootcampreview',
    BLOG_TAGS: BASE_API_URL + '/api/companyblogtags',
    BLOG_SEARCH: BASE_API_URL + '/api/companyblog',
    BLOG_POPULAR: BASE_API_URL + '/api/companyblogPopular',
    TEAM: BASE_API_URL + '/api/companyteam',
    PRODUCT: BASE_API_URL + '/api/companyproduct',
    BOOTCAMP_GALLERY: BASE_API_URL + '/api/bootcampGallery',
    BOOTCAMP_INSTRUCTOR: BASE_API_URL + '/api/bootcampInstructor',
    CAREER: BASE_API_URL + '/api/careerinfo',
    PORTFOLIO_PREAMBLE: BASE_API_URL + '/api/companyPortfolioPreamble',
    PORTFOLIO: BASE_API_URL + '/api/companyPortfolios',
    CLIENT_IMAGE_HEADER: BASE_API_URL + '/api/siteHeaderImage',
    HOME_TITLE_SUBTITLE: BASE_API_URL + '/api/homeHeaderTitleSubtitle',
    HOME_OURSERVICE_DESC: BASE_API_URL + '/api/homeOurservicesDescription',
    APPLY_CAREER: BASE_API_URL + '/api/applyCareer',
    COMBO: `${BASE_API_URL}/api/combo`,
};
// '6Lc54a0UAAAAALzAdimppzinycBUCtzTTCzD3o-E'
export const RECAPTCHA_KEY = ENV() === 'production' ? '6Lc54a0UAAAAALzAdimppzinycBUCtzTTCzD3o-E' : '6LcH460UAAAAAESMCDyDldrRtCMKEBJxx-og8IcQ';
