import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/ArrowForward'
import NavigateBeforeIcon from '@material-ui/icons/ArrowBack'
import Slide from '@material-ui/core/Slide'
// import Fade from '@material-ui/core/Fade'
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    buttonIcon: {
        fontSize: 60,
    },
    buttonBase: {
        minWidth: 60,
        padding: 0,        
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, .2)',
        //opacity: '.5',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .9)',
            color: 'white',
            opacity: '1'
        },
    },
    buttonPrev: {        
        //
    },
    buttonNext: {        
        //
    },
    img: {
        //maxWidth: 'inherit',
        width: '100%'
    },
    containerImg: {
        width: '100%',
        overflow: 'hidden',
    },
    containerImgSm: {        
        width: '100%',
        overflowX: 'hidden',
    },
    imgSm: {
        width: '100%',
    },
    paper: {
        maxHeight: 'calc(100% - 20px)'
    },
    buttonIconSm: {
        fontSize: 40,
    },
    buttonBaseSm: {
        minWidth: 40,
    }
});

const ImgGaleryDialog = (props) => {
    const { onClose, selectedValue, open, imgList } = props;

    const classes = useStyles();    
    const [localSelect, setLocalSelect] = useState(selectedValue)
    const [loading, setLoading] = useState(true)
    const [direction, setDirection] = useState("right")
    
    useEffect(() => {
        setLocalSelect(selectedValue)
    }, [selectedValue])

    function handleClose() {
        onClose(selectedValue);
    }

    function handlePrev() {
        setLoading(false)
        setDirection("right")
        setTimeout(() => {
            setLocalSelect(c => {
                let a = c - 1
                return a < 0 ? imgList.length-1 : a
            })
            setDirection("left")
            setLoading(true)
        }, 100);
        
    }

    function handleNext() {
        setLoading(false)
        setDirection("left")

        setTimeout(() => {
            setLocalSelect(c => {
                let a = c + 1
                return a > imgList.length-1 ? 0 : a
            })
            setDirection("right")
            setLoading(true)
        }, 100)
    }

    return (
        <Dialog onClose={handleClose} 
            aria-labelledby="Image Galery" open={open}
            maxWidth="xl"
            PaperComponent="div"
            PaperProps={{
                className: classes.paper,
                style: { 
                    margin: 10,
                    width: 1280
                }
            }}
            >
            <Hidden xsDown>
                <div style={{ position: 'absolute', right: '1%' }}>
                    <Button variant="contained" className={clsx(classes.buttonBase)} onClick={handleClose}>
                        <CloseIcon className={classes.buttonIcon}/>
                    </Button>
                </div>
                <Grid container className={clsx(classes.containerImg)} alignItems="center" alignContent="center">
                    <Grid item sm={1} style={{ textAlign: 'center' }}>
                        {/* <Fade in={loading}> */}
                            <Button variant="contained" className={clsx(classes.buttonBase, classes.buttonPrev)} onClick={handlePrev}>
                                <NavigateBeforeIcon className={classes.buttonIcon}/>
                            </Button>
                        {/* </Fade> */}
                    </Grid>
                    <Grid item sm={10} style={{ textAlign: 'center' }}>
                        <Slide in={loading} direction={direction} unmountOnExit mountOnEnter>
                            <img src={imgList[localSelect]} alt="" title="" className={classes.img}/>                    
                        </Slide>
                    </Grid>
                    <Grid item sm={1} style={{ textAlign: 'center' }}>
                        {/* <Fade in={loading}> */}
                            <Button variant="contained" className={clsx(classes.buttonBase, classes.buttonNext)} onClick={handleNext}>
                                <NavigateNextIcon className={classes.buttonIcon}/>
                            </Button>
                        {/* </Fade> */}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden smUp>
                <div style={{ position: 'absolute', right: '1%', top: '2%' }}>
                    <Button variant="contained" className={clsx(classes.buttonBase, classes.buttonBaseSm)} onClick={handleClose}>
                        <CloseIcon className={classes.buttonIconSm}/>
                    </Button>
                </div>
                <Grid container className={clsx(classes.containerImgSm)} alignItems="center" alignContent="center">
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Slide in={loading} direction={direction} unmountOnExit mountOnEnter>
                            <img src={imgList[localSelect]} alt="" title="" className={classes.imgSm}/>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 50}}>
                        <Grid container alignItems="center" alignContent="center" justify="space-evenly">
                            <Grid item>
                                {/* <Fade in={loading} timeout={0}> */}
                                    <Button variant="contained" className={clsx(classes.buttonBase, classes.buttonPrev, classes.buttonBaseSm)} onClick={handlePrev}>
                                        <NavigateBeforeIcon className={classes.buttonIconSm}/>
                                    </Button>
                                {/* </Fade> */}
                            </Grid>
                            <Grid item>
                                {/* <Fade in={loading} timeout={0}> */}
                                    <Button variant="contained" className={clsx(classes.buttonBase, classes.buttonNext, classes.buttonBaseSm)} onClick={handleNext}>
                                        <NavigateNextIcon className={classes.buttonIconSm}/>
                                    </Button>
                                {/* </Fade> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>        
            </Hidden>
        </Dialog>
    );
}

ImgGaleryDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.number.isRequired,
    imgList: PropTypes.arrayOf(PropTypes.string)
};
export default ImgGaleryDialog