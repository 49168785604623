import React, { Fragment, useState, useEffect } from 'react'
import { API_URL, STATIC_URL } from '../utils/Constant'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    loading: {
        color: '#a2d729',        
    },
}))

const Line = ({ width }) => {
    return <hr className="line" style={{ width }}/>
}


export default () => {
    const [loading, setLoading] = useState(false)
    const [team, setTeam] = useState([])
    const classes = useStyles()

    useEffect(() => {
        setLoading(true)
        const getTeam = async () => {
            return await fetch(API_URL.TEAM)
                .then(r => r.json())
                .catch(r => console.log(r))
        }
        getTeam().then(r => {
            let { data, status, message } = r

            setLoading(false)
            if(status) {
                setTeam(data)
            } else {
                console.log(message)
            }
        })
    }, [])

    const section1 = team.filter(fe => fe.seq === '0')
    const section2 = team.filter(fe => fe.seq === '1')
    const section3 = team.filter(fe => fe.seq === '2')
    const section4 = team.filter(fe => fe.seq === '3')

    function isOdd(arr) {
        let newArr
        newArr = arr
        return newArr
    }

    return (
        <Fragment>
            <section className="mbr-section article content10 cid-ryy6Dn6XNL" id="content10-a4">
                <div className="container">
                    <div className="inner-container" style={{ width: '66%' }}>
                        <Line width="25%" />
                            <div className="section-text align-center mbr-white mbr-fonts-style display-2">
                             Our Team
                            </div>
                        <Line width="25%"/>
                    </div>
                </div>
            </section>
 
            <section className="team1 cid-ru1fyPshVY mb-4 col-12" id="team1-1q">
                <div className="container align-center">
                    <div className="row media-row">
                        { loading && <CircularProgress size={100} className={classes.loading} />}
                        {isOdd(section1).map(fe => {
                            let linkedin 
                            if(fe.linkedin !== '') {
                                linkedin =<a href={fe.linkedin} target="_blank" rel="noopener noreferrer">
                                <span className="p-1 mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"></span>
                                </a>
                            } else { linkedin = <br/> }                              
                                return (
                                    <div className="team-item col-lg-3 col-md-6">
                                        <div className="meida-container-row">
                                            <div className="item-image">
                                                <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                            </div>
                                        <div className="item-caption py-3">
                                            <div className="item-name px-2 ceo">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                            <div className="item-role px-2">
                                                <p>
                                                    { fe.title }
                                                </p>
                                            </div>
                                            <div className="item-social pt-2">
                                                    { linkedin }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="mbr-ceo-text mb-0 mbr-fonts-style display-7 col-12 col-md-9">
                            <p>
                                "We are changing the world with software technology".
                            </p>
                        </div>
                    </div>
                </div>
            </section>
                        
            <section className="team1 cid-ru1fyPshVX mb-2" id="team1-1q">
                <div className="container align-center">
                    <div className="row media-row">
                        {loading && <CircularProgress size={100} className={classes.loading} />}
                        {isOdd(section2).map(fe => {
                            let linkedin 
                            if(fe.linkedin !== '') {
                                linkedin =<a href={fe.linkedin} target="_blank" rel="noopener noreferrer">
                                <span className="p-1 mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"></span>
                                </a>
                             } else { linkedin = <br/> }                             
                            if(isOdd(section2).length % 2 === 0) {
                                return (
                                    <div className="team-item col-lg-3 col-md-6">
                                        <div className="item-image">
                                            <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                        </div>
                                        <div className="item-caption py-3 card">
                                            <div className="item-name px-2">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                        <div className="item-role px-2">
                                            <p>
                                                { fe.title }
                                            </p>
                                        </div>
                                        <div className="item-social pt-2">
                                                { linkedin }
                                        </div>
                                    </div>
                                </div>
                                )
                            } else {
                                return (
                                    <div className="team-item col-lg-3 mbr-image col-md-6">
                                        <div className="item-image">
                                            <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                        </div>
                                        <div className="item-caption py-3 card">
                                            <div className="item-name px-2">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                            <div className="item-role px-2">
                                                <p>
                                                    { fe.title }
                                                </p>
                                            </div>
                                            <div className="item-social pt-2">
                                                    { linkedin }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } 
                        })}                        
                    </div>
                </div>
            </section>

            <section className="team1 cid-ru1fyPshVX mb-2" id="team1-1q">
                <div className="container align-center">
                    <div className="row media-row">
                        {loading && <CircularProgress size={100} className={classes.loading} />}
                        {isOdd(section3).map(fe => {
                            let linkedin 
                            if(fe.linkedin !== '') {
                                linkedin =<a href={fe.linkedin} target="_blank" rel="noopener noreferrer">
                                <span className="p-1 mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"></span>
                                </a>
                            } else { linkedin = <br/> }                              
                            if(isOdd(section3).length % 2 === 0) {
                                return (
                                    <div className="team-item col-lg-3 col-md-6">
                                        <div className="item-image">
                                            <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                        </div>
                                        <div className="item-caption py-3 card">
                                            <div className="item-name px-2">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                            <div className="item-role px-2">
                                                <p>
                                                    { fe.title }
                                                </p>
                                            </div>
                                            <div className="item-social pt-2">
                                                    { linkedin }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="team-item col-lg-3 mbr-image col-md-6">
                                        <div className="item-image">
                                            <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                        </div>
                                        <div className="item-caption py-3 card">
                                            <div className="item-name px-2">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                            <div className="item-role px-2">
                                                <p>
                                                    { fe.title }
                                                </p>
                                            </div>
                                            <div className="item-social pt-2">
                                                    { linkedin }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}                        
                    </div>
                </div>
            </section>
            
            <section className="team1 cid-ru1fyPshVX mb-2" id="team1-1q">
                <div className="container align-center">
                    <div className="row media-row">
                        {loading && <CircularProgress size={100} className={classes.loading} />}
                        {isOdd(section4).map(fe => {
                            let linkedin 
                            if(fe.linkedin !== '') {
                                linkedin =<a href={fe.linkedin} target="_blank" rel="noopener noreferrer">
                                <span className="p-1 mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"></span>
                                </a>
                            } else { linkedin = <br/>}                          
                            if(isOdd(section4).length % 2 === 0) {
                                return (
                                    <div className="team-item col-lg-3 col-md-6">
                                        <div className="item-image">
                                            <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                        </div>
                                        <div className="item-caption py-3 card">
                                            <div className="item-name px-2">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                            <div className="item-role px-2">
                                                <p>
                                                    { fe.title }
                                                </p>
                                            </div>
                                            <div className="item-social pt-2">
                                                    { linkedin } 
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="team-item col-lg-3 mbr-image col-md-6">
                                        <div className="item-image">
                                            <img src={ STATIC_URL + fe.path } alt="" title={ fe.fullname } />
                                        </div>
                                        <div className="item-caption py-3 card">
                                            <div className="item-name px-2">
                                                <p className="mbr-fonts-style display-5">
                                                    { fe.fullname }
                                                </p>
                                            </div>
                                            <div className="item-role px-2">
                                                <p>
                                                    { fe.title }
                                                </p>
                                            </div>
                                            <div className="item-social pt-2">
                                                    { linkedin }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}                        
                    </div>
                </div>
            </section>
        </Fragment>
    )
}