import React, { useState, useEffect } from 'react'
import { API_URL, STATIC_URL } from '../utils/Constant'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import { initTestimonialsPlugin } from '../assets/js/CustomScript'

const useStyles = makeStyles(theme => ({
    loading: {
        color: '#a2d729',        
    },
}))

export default () => {
    const classes = useStyles()
    const [testimonies, setTestimonies] = useState([])
    const [loadingTestimony, setLoadingTestimony] = useState(false)

    useEffect(() => {        
        setLoadingTestimony(true)

        const fetchTestimony = async () => {
            return await fetch(API_URL.TESTIMONIES)
                .then(resp => resp.json())
                .catch((error) => {
                    console.log("home", error.message)
                    return { status: false }
                })
        }
        fetchTestimony().then(({ status, data }) => { 
            setLoadingTestimony(false)
            if(status){
                setTestimonies(data)
                setTimeout(() => {
                    initTestimonialsPlugin()
                }, 500); 
            }
        })
    }, [])

    return (
        <section className="carousel slide testimonials-slider cid-ruGiBhmKf9" data-interval="false" id="testimonials-slider1-3n">
            { loadingTestimony && 
                <div className="row">
                    <div className="col-md-12">
                        <div className="align-center">
                            <Fade in={loadingTestimony} timeout={300} unmountOnExit>
                                <CircularProgress className={classes.loading} size={100}/>
                            </Fade>
                        </div>
                    </div>
                </div>
            }
            <div className="container text-center">
                <div className="carousel slide" role="listbox" data-pause="true" data-keyboard="false" data-ride="carousel" data-interval="4000">
                    <div className="carousel-inner" data-visible="1">
                        { testimonies.map((hiji, index) => {
                            return <div className="carousel-item" key={index}>
                                <div className="user col-md-8" key={index}>
                                    <div className="user_image">
                                        <img src={ STATIC_URL + hiji.path } alt={ hiji.name }/>
                                    </div>
                                    <div className="user_text pb-3">
                                        <p className="mbr-fonts-style display-5" style={{ fontStyle: 'italic', fontSize: '1.25rem' }}>
                                            "{ hiji.testimony }"
                                        </p>
                                    </div>
                                    <div className="user_name mbr-bold pb-2 mbr-fonts-style display-7">
                                        { hiji.name }
                                    </div>
                                    <div className="user_desk mbr-light mbr-fonts-style display-7">
                                        { hiji.title }
                                    </div>                                    
                                </div>
                            </div>
                        }) }                        
                        {/* <div className="carousel-item">
                            <div className="user col-md-8">
                                <div className="user_image">
                                    <img src="assets/images/face2.jpg" alt="alt"/>
                                </div>
                                <div className="user_text pb-3">
                                    <p className="mbr-fonts-style display-7">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae nostrum, quos voluptas fugiat blanditiis, temporibus expedita cumque doloribus ea, officiis consequuntur repellat minus ad veritatis? Facere similique accusamus, accusantium sunt!"</p>
                                </div>
                                <div className="user_name mbr-bold pb-2 mbr-fonts-style display-5">
                                    Linda
                                </div>
                                <div className="user_desk mbr-light mbr-fonts-style display-5">
                                    DEVELOPER
                                </div>
                            </div>
                        </div> */}
                    </div>                       
                    {testimonies.map((hiji, index) => {
                        if(hiji.is_show === 'Y' || hiji.is_show === 'y') {
                            return (
                                <div className="carousel-controls">    
                                {/* eslint-disable-next-line */ }                        
                                    <a className="carousel-control-prev" role="button" data-slide="prev">
                                        <span aria-hidden="true" className="mbri-arrow-prev mbr-iconfont"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                {/* eslint-disable-next-line */ }
                                    <a className="carousel-control-next" role="button" data-slide="next">
                                        <span aria-hidden="true" className="mbri-arrow-next mbr-iconfont"></span>
                                        <span className="sr-only">Next</span>
                                    </a>                            
                                </div> 
                                )
                            }
                        }
                    )}                     
                </div>
            </div>
        </section>
    )
}