import Helmet from 'react-helmet'
import React, { Fragment, useEffect, useState } from 'react'

import { useFetchMeta, useFetchImageHeader } from '../utils/Fetch'
import { initAnimation } from '../assets/js/CustomScript'
import { makeStyles } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowRight  from '@material-ui/icons/KeyboardArrowRight'

import { API_URL, STATIC_URL } from '../utils/Constant'

import '../assets/css/career.css'

const useStyles = makeStyles(theme => ({
    font: {
        fontSize: '1.5rem'
    },
    icon: {
        color: 'rgb(249, 133, 51) !important',
        fill: 'rgb(249, 133, 51) !important'
    },
    reviewLine: {
        borderTop: '1px solid rgba(200, 200, 200, 1)',
        width: '80%'
    },
    accordion: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 744px)']: { minWidth: 200 },
    }

}))

const Accordion = withStyles({
  root: {   
    padding: '5px',
    marginBottom: '12px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
        },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles( theme => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: '#a2d729',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginBottom: -1,
    minHeight: 56,
    minWidth: 1200,
    [theme.breakpoints.down('sm')]: {
            minWidth: 200,
    },
    boxShadow: '2px 4px 7px 3px #aeaeae',
    '&$expanded': {
      boxShadow: 'none',
      minHeight: 56,
      backgroundColor: '#a2d729',
      color: '#eaeaea',
        },
     // eslint-disable-next-line no-useless-computed-key

  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    '&$expanded': {
        transform: 'rotate(90deg)'
    },
  }
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


export default ({ location: { pathname } }) => {
    const { metaData } = useFetchMeta(pathname)
    const { imgUrl } = useFetchImageHeader('career_header')
    const classes = useStyles()
    const [career, setCareer] = useState([])
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState('panel0')

    useEffect(() => {
        initAnimation()

        const getCareer = async () => {
            return await fetch(API_URL.CAREER)
                .then(r => r.json())
                // .catch(r => console.log(r))
        }
        setLoading(true)
        getCareer().then(r => {
            setLoading(false)
            let { status, data } = r
            if(status) {
                setCareer(data)
            }
        })

    }, [])
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded && panel)
        console.log(panel, isExpanded, expanded)
      };
    
    return (
        <Fragment>
            <Helmet>
                <title>Career</title>
                <meta name="description" content={metaData != null ? metaData.description: ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
            </Helmet>
            <section className="cid-rwgnEqBGkA mbr-parallax-background" id="header2-54" style={{ backgroundImage: `url(${imgUrl})`}}>
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">CAREER</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-section article cid-career pt-5 mb-4" id="come-join-us">
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="col-12 display-2" style={{fontSize: "2rem", paddingBottom: "20px"}}>Come join us,</div>
                        <div className="col-12 display-2" style={{color: '#a2d729', fontWeight: 400}}>
                            Apply for right position!
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-section article cid-career mb-4" id="career-info">
                <div className="container">
                    <div className="row justify-content-md-center">
                        { career.map((fe, index) => (
                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={`accordion-${index}`}>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowRight />}
                                >
                                    <Typography >
                                    <b className="cid-career-acordion-position">{`${fe.position}`}</b> 
                                    <span className="cid-career-acordion-title">{`(${fe.employment_type})`}</span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="container">
                                    <div className="media-container-row">
                                        <div className="col-12 col-md-12">
                                            <p dangerouslySetInnerHTML={{__html:fe.description}} />
                                            <div className=" align-center">
                                                <a className="btn cid-career-btn display-4" href={`/career/apply?position=${fe.position}&employment=(${fe.employment_type})`}>
                                                    Apply Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}