import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { API_URL } from '../utils/Constant'
import { useFetchMeta, useFetchImageHeader, useFetch } from '../utils/Fetch'
import { initAnimation, initArrowDown } from '../assets/js/CustomScript'
import Testimony from './Testimony';
import Client from './Client'
import SectionTitle from '../common/SectionTitle'
import clsx from 'clsx'

import '../assets/css/home.css'

export default ({ location }) => {
    const { metaData } = useFetchMeta(location.pathname)
    const { imgUrl } = useFetchImageHeader('home_header')
    const { data: homeHeader } = useFetch(API_URL.HOME_TITLE_SUBTITLE)
    
    let title, subtitle 
    if(homeHeader.status) {
        title = homeHeader.data.title
        subtitle = homeHeader.data.subtitle
    }

    const { data: homeOurservice } = useFetch(API_URL.HOME_OURSERVICE_DESC)
    let bootcamp, itconsultant, products
    if(homeOurservice.status) {
        bootcamp = homeOurservice.data.bootcamp
        itconsultant = homeOurservice.data.itconsultant
        products = homeOurservice.data.products
    }

    useEffect(() => {
        initAnimation()
        initArrowDown()
    }, [])

    return (
        <Fragment>
            <Helmet>
                <meta name="description" content={metaData != null ? metaData.description: ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
            </Helmet>            
            <section className={ clsx('header9', 'cid-ruWyMWxGGZ', 'mbr-fullscreen', 'mbr-parallax-background') } 
                style={{ backgroundImage: `url(${imgUrl})` }} id="header9-3r">
                <div className="mbr-overlay" style={{ opacity: 0.6, backgroundColor: 'rgb(35, 35, 35)'}}>
                </div>
                <div className="container">
                    <div className="media-container-column mbr-white col-lg-8 col-md-10">
                        <h1 className="mbr-section-title align-left mbr-bold pb-3 mbr-fonts-style display-2" 
                            dangerouslySetInnerHTML={{ __html: title }}>                            
                        </h1>                        
                        <p className="mbr-text align-left pb-3 mbr-fonts-style display-5">
                            {/* We offer products and services for every role in the enterprise. */}
                            { subtitle }
                        </p>
                        <div className="mbr-section-btn align-left">
                            <Link className="btn btn-md btn-primary display-4" to="#content10-9p">
                                LEARN MORE
                            </Link>
                            <a class="btn btn-md btn-white-outline display-4" href="/contactUs#form4-4s">CONTACT US</a>
                        </div>
                    </div>
                </div>
                <div className="mbr-arrow hidden-sm-down" aria-hidden="true">
                    <a href="#next">
                        <i className="mbri-down mbr-iconfont"></i>
                    </a>
                </div>
            </section>
            <section className="mbr-section article content10 cid-rys5Ncjm3O" id="content10-9p">                
                <SectionTitle title="Our Services" />
            </section>
            <section className="features8 cid-runYY9kxkA" id="features8-3c">                
                <div className="container">
                    <div className="media-container-row">
                        <div className="card  col-12 col-md-6 col-lg-4">
                            <div className="card-img">
                                <span className="mbr-iconfont mbri-rocket ourservice-icon"></span>
                            </div>
                            <div className="card-box align-center">
                                <h4 className="card-title mbr-fonts-style display-7">
                                    Bootcamp
                                </h4>
                                <p className="mbr-text mbr-fonts-style display-7">
                                    { bootcamp }
                                </p>
                                <div className="mbr-section-btn text-center">
                                    <a href="/bootcamp" className="btn btn-primary display-4">
                                        More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card  col-12 col-md-6 col-lg-4">
                            <div className="card-img">
                                <span className="mbr-iconfont mbri-idea ourservice-icon"></span>
                            </div>
                            <div className="card-box align-center">
                                <h4 className="card-title mbr-fonts-style display-7">
                                    IT Consultant
                                </h4>
                                <p className="mbr-text mbr-fonts-style display-7">
                                    { itconsultant }
                                </p>
                                <div className="mbr-section-btn text-center">
                                    <a href="/itConsultant" className="btn btn-primary display-4">
                                        More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card  col-12 col-md-6 col-lg-4">
                            <div className="card-img">
                                <span className="mbr-iconfont mbri-responsive ourservice-icon"></span>
                            </div>
                            <div className="card-box align-center">
                                <h4 className="card-title mbr-fonts-style display-7">
                                    Products
                                </h4>
                                <p className="mbr-text mbr-fonts-style display-7">
                                    { products }
                                </p>
                                <div className="mbr-section-btn text-center">
                                    <a href="/products" className="btn btn-primary display-4">
                                        More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Client />            
            <Testimony />
        </Fragment>
    )
}