import React, { Fragment, useEffect } from 'react'
import Helmet from 'react-helmet'
import clsx from 'clsx'
import YouTube from '@u-wave/react-youtube';
import { makeStyles } from '@material-ui/core'
import { useFetchMeta } from '../utils/Fetch'
import { initAnimation, initArrowDown } from '../assets/js/CustomScript'
import OurTeam from './OurTeam';

const useStyles = makeStyles(theme => ({
    youtube: {
        bottom: 0,
        left: 0,        
        position: 'absolute',
        right: 0,
        top: '-8%',
        zIndex: 0,
        // pointerEvents: 'none',
        width: '100%',
        height: '115%'
    },
    icon: { 
        color: 'rgb(249, 133, 51) !important', 
        fill: 'rgb(249, 133, 51) !important', 
        fontSize: 80 
    },
    title: {
        color: '#767676',
        fontWeight: 300,
        textAlign: 'center',
        marginBottom: 40
    }
}))

export default ({ location: { pathname } }) => {
    const { metaData } = useFetchMeta(pathname)
    const classes = useStyles()
   
    useEffect(() => {
        initAnimation()
        initArrowDown()
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content={metaData != null ? metaData.description: ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
            </Helmet>
            <section className="header6 cid-ruXztT4Loc mbr-fullscreen" id="header6-43">
                <YouTube 
                    video="bb3oFsaGmlc"
                    className={clsx(classes.youtube)}
                    autoplay
                    controls={false}
                    disableKeyboard={true}
                    allowFullscreen={true}
                    showRelatedVideos={false}
                    muted={true}
                    modestBranding={false}
                    showInfo={false}
                />
                <div className="mbr-overlay" style={{ opacity: '0.3', backgroundColor: 'rgb(35, 35, 35)'}}></div>

                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                        </div>
                    </div>
                </div>

                <div className="mbr-arrow hidden-sm-down" aria-hidden="true">
                    <a href="#next">
                        <i className="mbri-down mbr-iconfont"></i>
                    </a>
                </div>
            </section>

            <section className="mbr-section article content3 cid-ru0VZ6JHHd" id="content3-1k">
                <div className="container">
                    <div className="media-container-row">
                        <div className="row col-12 col-md-12">
                            <div className="col-12 mbr-text mbr-fonts-style col-md-12 display-2">
                                <p style={{ paddingLeft: 50 }}>WHO WE ARE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section content6 cid-ru1d3eak3p" id="content6-1n">
                <div className="container">
                    <div className="media-container-row">
                        <div className="col-12 col-md-8">
                            <div className="media-container-row">
                                <div className="mbr-figure" style={{ width: '50%' }}>
                                    <img src="assets/images/0-200x200.png" alt="Mobirise" title="" />  
                                </div>
                                <div className="media-content">
                                    <div className="mbr-section-text">
                                        <p className="mbr-text mb-0 mbr-fonts-style display-7">
                                            <strong>Bee Solution Partners </strong>
                                            is a professional Information Technology company providing bootcamp, consultant and offshore has gained trust among private companies, 
                                            industry and government since 2007. <br />
                                            We also provides Information Technology consulting services to create an application. 
                                            Our experience and insight spans across all industries and sectors including government, health, banking, manufacturing and many more. <br />
                                            We work closely with our clients on every project, <br />
                                            walking through a comprehensive methodology that focuses on strategy, satisfies industry regulations, and 
                                            ultimately achieves business goals.<br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="counters1 counters cid-rumYVelL6K" id="counters1-21">
                <div className="container">                    
                    <div className="container pt-4">
                        <div className="media-container-row">

                            <div className="card p-3 align-center col-12 col-md-6 col-lg-4">
                                <div className="panel-item">
                                    <div className="card-text">
                                        <h3 className={classes.title}>
                                            COMPLETED
                                        </h3>
                                    </div>
                                    <div className="card-img pb-3">
                                        <span className={clsx("mbr-iconfont", "mbri-responsive", classes.icon)} />
                                    </div>
                                    <div className="card-text">
                                        <h3 className="count pt-3 pb-3 mbr-fonts-style display-2">
                                            100
                                        </h3>
                                        <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                            PROJECTS
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card p-3 align-center col-12 col-md-6 col-lg-4">
                                <div className="panel-item">
                                    <div className="card-text">
                                        <h3 className={classes.title}>
                                            SERVED
                                        </h3>
                                    </div>
                                    <div className="card-img pb-3">
                                        <span className={clsx("mbr-iconfont", "mbri-users", classes.icon)} />
                                    </div>
                                    <div className="card-text">
                                        <h3 className="count pt-3 pb-3 mbr-fonts-style display-2">
                                            10
                                        </h3>
                                        <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                            CLIENTS
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card p-3 align-center col-12 col-md-6 col-lg-4">
                                <div className="panel-item">
                                    <div className="card-text">
                                        <h3 className={classes.title}>
                                            CLIENT RELATIONSHIP FOR
                                        </h3>
                                    </div>
                                    <div className="card-img pb-3">
                                        <span className={clsx("mbr-iconfont", "mbri-calendar", classes.icon)} />                                        
                                    </div>
                                    <div className="card-text">
                                        <h3 className="count pt-3 pb-3 mbr-fonts-style display-2">
                                            11
                                        </h3>
                                        <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                            YEARS
                                        </h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content3 cid-ru1i2vN3U5" id="content3-1s">
                <div className="container">
                    <div className="media-container-row">
                        <div className="row col-12 col-md-12">
                            <div className="col-12 mbr-text mbr-fonts-style col-md-12 display-2">
                                <p>&nbsp; &nbsp; &nbsp;VISION</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content1 cid-ru1jhfi4aQ" id="content2-1t">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text col-12 col-md-8 mbr-fonts-style display-7">
                            <blockquote><strong>Become a provider of quality and value-added information and communication technology and solutions with value-added customers and stakeholders.</strong></blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content3 cid-ru1juOQz10" id="content3-1u">
                <div className="container">
                    <div className="media-container-row">
                        <div className="row col-12 col-md-12">
                            <div className="col-12 mbr-text mbr-fonts-style col-md-12 display-2">
                                <p>&nbsp; &nbsp; &nbsp;MISSION</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article content11 cid-ru1wW4Bzjd" id="content11-1y">
                <div className="container">
                    <div className="media-container-row">
                        <div className="mbr-text counter-container col-12 col-md-8 mbr-fonts-style display-7">
                            <ol>
                                <li><strong>Develop Quality Information Technology Products.</strong></li>
                                <li><strong>Prioritizes Professionalism and Collaboration in Providing Quality Services.</strong></li>
                                <li><strong>Curb Unemployment by Recruiting and Developing Sustainable Human Resources.</strong></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="mbr-section article content3 cid-ru1edsOjXH" id="content3-1p">
                <div class="container">
                    <div class="media-container-row">
                        <div class="row col-12 col-md-12">
                            <div class="col-12 mbr-text mbr-fonts-style col-md-12 display-2">
                                <p>&nbsp; &nbsp; &nbsp;OUR TEAM</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="team1 cid-ru1fyPshVX mb-4" id="team1-1q">
                <div class="container align-center">
                    <div class="row media-row">
                        <div class="team-item col-lg-3 col-md-6">
                            <div class="item-image">
                                <img src="assets/images/face3-1.jpg" alt="" title="" />
                            </div>
                            <div class="item-caption py-3">
                                <div class="item-name px-2">
                                    <p class="mbr-fonts-style display-5">
                                        Steven Bruce
                                    </p>
                                </div>
                                <div class="item-role px-2">
                                    <p>
                                        Developer
                                    </p>
                                </div>
                                <div class="item-social pt-2">
                                    <a href="https://www.linkedin.com/in/mobirise" target="_blank" rel="noopener noreferrer">
                                        <span class="p-1 mbr-iconfont mbr-iconfont-social socicon-linkedin socicon"></span>
                                    </a>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <OurTeam />
        </Fragment>
    )
}