import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: 120
    }
}))

export default () => {
    const classes = useStyles()

    return (
        <section className={clsx("mbr-section", "content4", "cid-rwgo15RJpn", classes.content)} id="content4-55">
            <div className="container">
                <div className="media-container-row">
                    <div className="title col-12 col-md-8 align-center">
                        <h3 className="align-center pb-3 mbr-fonts-style display-2">
                            Page Not Found
                        </h3>
                        <h4 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
                            There is nothing here. Back to&nbsp;
                            <Link className="" to="/">
                                Home
                            </Link>
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    )
}
    