import $ from 'jquery'

$.isMobile = function(type) {
    var reg = [];
    var any = {
        blackberry: 'BlackBerry',
        android: 'Android',
        windows: 'IEMobile',
        opera: 'Opera Mini',
        ios: 'iPhone|iPad|iPod'
    };
    type = 'undefined' === $.type(type) ? '*' : type.toLowerCase();
    if ('*' === type) reg = $.map(any, function(v) {
        return v;
    });
    else if (type in any) reg.push(any[type]);
    return !!(reg.length && navigator.userAgent.match(new RegExp(reg.join('|'), 'i')));
};

const initAnimation = () => {
    if ($.isMobile()) {
        return;
        // enable animation on scroll
    } else if ($('input[name=animation]').length) {
        $('input[name=animation]').remove();

        var $animatedElements = $('p, h1, h2, h3, h4, h5, a, button, small, img, li, blockquote, .mbr-author-name, em, label, input, textarea, .input-group, .iconbox, .btn-social, .mbr-figure, .mbr-map, .mbr-testimonial .card-block, .mbr-price-value, .mbr-price-figure, .dataTable, .dataTables_info').not(function() {
            return $(this).parents().is('.navbar, .mbr-arrow, footer, .iconbox, .mbr-slider, .mbr-gallery, .mbr-testimonial .card-block, #cookiesdirective, .mbr-wowslider, .accordion, .tab-content, .engine, #scrollToTop');
        }).addClass('hidden animated');

        function getElementOffset(element) {
            var top = 0;
            do {
                top += element.offsetTop || 0;
                element = element.offsetParent;
            } while (element);

            return top;
        }

        function checkIfInView() {
            var window_height = window.innerHeight;
            var window_top_position = document.documentElement.scrollTop || document.body.scrollTop;
            var window_bottom_position = window_top_position + window_height - 50;

            $.each($animatedElements, function() {
                var $element = $(this);
                var element = $element[0];
                var element_height = element.offsetHeight;
                var element_top_position = getElementOffset(element);
                var element_bottom_position = (element_top_position + element_height);

                // check to see if this current element is within viewport
                if ((element_bottom_position >= window_top_position) &&
                    (element_top_position <= window_bottom_position) &&
                    ($element.hasClass('hidden'))) {
                    $element.removeClass('hidden').addClass('fadeInUp')
                        .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                            $element.removeClass('animated fadeInUp');
                        });
                }
            });
        }

        var $window = $(window);
        $window.on('scroll resize', checkIfInView);
        $window.trigger('scroll');
    }

    window.scrollTo(0, 0)
}

const initArrowDown = () => {
    // taken from /public/assets/theme/js/script.js
    $('.mbr-arrow').on('click', function(e) {            
        var $next = $(e.target).closest('section').next();
        if($next.hasClass('engine')){
            $next = $next.closest('section').next();
        }
        var offset = $next.offset();
        $('html, body').stop().animate({
            scrollTop: offset.top
        }, 800, 'linear');
    });
}

const initClientPlugin = () => {
    // console.log("pre init client plugin", window.initClientPlugin)
    // if(typeof window.initClientPlugin === 'undefined'){
        if($(document.body).find('.clients').length !== 0){
            window.initClientPlugin = true;
            $(document.body).find('.clients').each(function(index, el) {
                if(!$(this).attr('data-isinit')) {
                    console.log("data-isinit")
                    initTestimonialsCarousel($(this));
                    initClientCarousel($(this));
                }  
            });
        }
    // }
    // console.log("post init client plugin", window.initClientPlugin)
}
const unsubClientPlugin = () => {
    window.initClientPlugin = undefined
    console.log("unsub client", window.initTestimonialsPlugin)
}

const initTestimonialsPlugin = () => {
    // console.log("pre init testimonial plugin", window.initTestimonialsPlugin)
    // if(typeof window.initTestimonialsPlugin === 'undefined'){
        if($(document.body).find('.testimonials-slider').length !== 0){
            window.initTestimonialsPlugin = true;
            $('.testimonials-slider').each(function(){
                initTestimonialsCarousel(this);
            }); 
        }
    // }
    // console.log("post init testimonial plugin", window.initTestimonialsPlugin)
}
const unsubTestimonialsPlugin = () => {
    window.initTestimonialsPlugin = undefined;
    console.log("unsub testimonial", window.initTestimonialsPlugin)
}

function setActiveCarouselItem(card){
    var $target = $(card).find('.carousel-item:first');
    $target.addClass('active');
}
function initTestimonialsCarousel(card){
    var $target = $(card),
        $carouselID = $target.attr('ID') +"-carousel"; 
        console.log("carouselID", $carouselID)
    $target.find('.carousel').attr('id',$carouselID);
    $target.find('.carousel-controls a').attr('href','#'+$carouselID);
    $target.find('.carousel-indicators li').attr('data-target','#'+$carouselID);
    setActiveCarouselItem($target);  
}
function initClientCarousel(card){
    var $target = $(card),
    countElems = $target.find('.carousel-item').length,
    visibleSlides = $target.find('.carousel-inner').attr('data-visible');
    if (countElems < visibleSlides){
        visibleSlides = countElems;
    }
    if(visibleSlides === undefined) {
        visibleSlides = 1
    }
    $target.find('.carousel-inner').attr('class', 'carousel-inner slides' + visibleSlides);
    $target.find('.clonedCol').remove();

    $target.find('.carousel-item .col-md-12').each(function() {
        if (visibleSlides < 2) {
            $(this).attr('class', 'col-md-12');
        } else if (visibleSlides === '5') {
            $(this).attr('class', 'col-md-12 col-lg-15');
        } else {
            $(this).attr('class', 'col-md-12 col-lg-' + 12 / visibleSlides);
        }
    });

    $target.find('.carousel-item').each(function() {
        var itemToClone = $(this);
        for (var i = 1; i < visibleSlides; i++) {
            itemToClone = itemToClone.next();
            if (!itemToClone.length) {
                itemToClone = $(this).siblings(':first');
            }
            var index = itemToClone.index();
            itemToClone.find('.col-md-12:first').clone().addClass('cloneditem-' + i).addClass('clonedCol').attr('data-cloned-index', index).appendTo($(this).children().eq(0));
        }
    });
}

export { initAnimation, initArrowDown, 
    initClientPlugin, unsubClientPlugin, 
    initTestimonialsPlugin, unsubTestimonialsPlugin }