import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import clsx from 'clsx'
import TextField from '@material-ui/core/TextField'
import { useFetchMeta } from '../utils/Fetch'
import { API_URL, RECAPTCHA_KEY } from '../utils/Constant'
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress, makeStyles } from '@material-ui/core'

import { initAnimation } from '../assets/js/CustomScript'
import '../assets/css/contactUs.css'
import {useFetchImageHeader} from '../utils/Fetch'
import {Alert, AlertTitle} from '@material-ui/lab'
import Snackbar from '@material-ui/core/Snackbar'
import Grid from '@material-ui/core/Grid'

const _MSG = []
_MSG['REQUIRED'] = 'Please fill out this field'
_MSG['FORMAT'] = 'Please fill out with correct format'

const isEmail = value => {
    const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
    return emailRegex.test(String(value).toLocaleLowerCase())
}

const isPhone = value => {
    var phoneno = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
    return phoneno.test(value)
}


const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        color: '#a2d729',
        position: 'absolute',
        top: '40%',
        left: '45%',
        marginTop: -12,
        marginLeft: -12,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        position: 'absolute',
        top: '0',
        left: '3%',
        right: 0,
        bottom: 0
    },
    helperText: {
        marginBottom: '50%'
    },
    name: {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 744px)']: { paddingBottom: '20px' },
    }
}))

const initData = {name: '', phone: '', email: '', message: '', subject: ''}
const initFormHelper = {
    name: {error: false, message: null},
    phone: {error: false, message: null},
    email: {error: false, message: null},
    message: {error: false, message: null},
    subject: {error: false, message: null}
    
}

const isEmpty = value => {
    if (typeof value === 'string' || value instanceof String) {
        return value.trim().length === 0
    }
    return value === '' || value == null || typeof value == 'undefined'
}

export default ({ location }) => {

    const classes = useStyles()
    const { metaData } = useFetchMeta(location.pathname)
    // const [data, setData] = useState([])
    const [formData, setFormData] = useState(initData)
    const [errorFormData, setErrorFormData] = useState(initData)
    const [captchaToken, setCaptchaToken] = useState('')
    const [postResult, setPostResult] = useState({ status: false, message: ''})
    const [loading, setLoading] = useState(false)
    const formEl = React.createRef()
    const { imgUrl } = useFetchImageHeader('contactus_header')
    // const [chars, setChars] = useState(initData)
    const [formMessage, setFormMessage] = useState({
        show: false,
        severity: null,
        title: null,
        message: null,
    })
    const [formHelper, setFormHelper] = useState(initFormHelper)
    const formRules = {
        name: [
            v => !isEmpty(v) || 'Name should not be empty',
        ],
        phone: [
            
            v => isPhone(v) ||'Please fill out with correct format'
        ],
        email: [
            v => isEmail(v) || 'Please enter a valid email address',
            v => !isEmpty(v) || 'Email should not be empty'
        ],
        subject: [
            v => !isEmpty(v) || 'Subject should not be empty'
        ],
        message: [
            v => !isEmpty(v) || 'Message should not be empty'
        ]
    }

    function onChangeCaptcha(value) {
        setCaptchaToken(value)
    }

    function onExpiredCaptcha() {
        setCaptchaToken('')
    }

    const validate = (name, value) => {
        if (formRules[name]) {
            for (const rule of formRules[name]) {
                const result = rule(value)
                if (result !== true) {
                    return { error: true, message: result }
                }
            }
            return { error: false, message: null }
        } else {
            return null
        }
    }

    const validateForm = () => {
        setErrorFormData(initData)
        
        let isValid = true
        const infos = {}
        const formDataCopy = Object.assign({}, formData)
        for(const field in formDataCopy) {
            let result 
            if(formDataCopy.phone === '') {
                delete formDataCopy.phone
                // result = {error: false, message: ''}
            } else {
                result = validate(field, formDataCopy[field])
            }
            result = validate(field, formDataCopy[field])
            if(result) {
                infos[field] = result
                if (result.error) {
                    isValid = false
                }
            }
        }
        setFormHelper(infos)
        return isValid
    }

    const handleOnChange = event => {
        const { name, value } = event.target
        setFormData(state => ({ ...state, [name]: value }))

        const result = validate(name, value)
        if (result) {
            setFormHelper(state => ({ ...state, [name]: result }))
        }
    }

    const handleSendMessage = (event) => {
        event.preventDefault();
        if(!validateForm()) {
            return;
        }
        const post = async () => {
            setLoading(true)
            return await fetch(API_URL.CONTACT_US, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'                    
                },
                body: JSON.stringify(formData)
            })
            .then(response => response.json())
            .then(({ status }) => {
                if(status) {
                    setFormMessage({
                        show: true,
                        severity: 'success',
                        title: 'Message sent successfully',
                        message: 'Thanks for filling out the form'
                    })
                    return Promise.resolve({ status})
                } else {
                    setFormMessage({
                        show: true,
                        severity: 'error',
                        title: 'Oops...',
                        message: 'something happened, please try again later'
                    })
                    return Promise.resolve({ status })
                }
            })
            .catch(err => {
                setFormMessage({
                    show: true,
                    severity: 'error',
                    title: 'Oops...',
                    message: 'something happened, please try again later'
                })
                Promise.resolve({status: false})
            })
        }

        post()
        .then(resp => {
            setPostResult(resp)
            setFormData(initData)
            setCaptchaToken('')
            setLoading(false)            
        })
    }

    const handleMessageClose = () => {
        setFormMessage({
            show: false,
            severity: 'info',
            title: '',
            message: '',
        })
    }

    useEffect(() => {        
        // async function fetchCompInfo() {
        //     const { data } =  await fetch(API_URL.COMPANY_INFO, {
        //         method: "get",
        //         headers: {
        //             "Content-Type": "application/json",
        //         }
        //     })
        //     .then((resp) => resp.json())
        //     .catch(err => console.log(err))

        //     const reduceMap = data.reduce((resultMap, one) => {
        //         const { code } = one                
        //         if (!resultMap[code]) {
        //             resultMap[code] = one
        //         }                
        //         return resultMap
        //     }, [])
        //     setData(reduceMap)
        // }
        // fetchCompInfo()
        initAnimation()
    }, [])

    return (
        <Fragment>
            <Helmet>
                <meta name="description" content={metaData != null ? metaData.description: ''} />
                <meta name="keywords" content={metaData != null ? metaData.keywords: ''} />
            </Helmet>            
            <section className="cid-rvOuMJ5WGp mbr-parallax-background" style={{backgroundImage: `url(${imgUrl})`}} id="header2-4t">
            <div class="mbr-overlay" style={{ opacity: 0.5, backgroundColor: 'rgb(0, 0, 0)'}}></div>
                <div className="container align-center">
                    <div className="row justify-content-md-center">
                        <div className="mbr-white col-md-10">
                            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">CONTACT US</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-section form4 cid-rxHA4h33sg" id="form4-4s">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="google-map">
                                <iframe 
                                    title="bsp-map"
                                    frameBorder="0" 
                                    style={{border:0, paddingTop: '5px'}}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.0783413746717!2d107.57014211432754!3d-6.881218295027513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e685af3df3e7%3A0x230873a4329685e1!2sPT+Bee+Solution+Partners!5e0!3m2!1sen!2sid!4v1563163885149!5m2!1sen!2sid" 
                                    allowFullScreen="">
                                </iframe>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-3">
                                <div className="icon-block pb-3 align-left">
                                    <span className="icon-block__icon">
                                        <span className="mbri-letter mbr-iconfont"></span>
                                    </span>
                                    <h4 className="icon-block__title align-left mbr-fonts-style display-5">
                                        Have any Question? <br />
                                        We have the Answers!
                                    </h4>
                                </div>
                                <div className="icon-contacts pb-3">
                                    <h5 className="align-left mbr-fonts-style display-7 mb-3">
                                        Feel free to get in touch with us. We are always ready for offers and cooperation.
                                    </h5>
                                    {/* <p className="mbr-text align-left mbr-fonts-style display-7">
                                        Email: { data["EMAIL"] && data["EMAIL"].value }<br />
                                        Phone: { data["PHONE"] && data["PHONE"].value }<br />
                                        Mobile/ WA: { data["WA"] && data["WA"].value }<br />
                                        <br />
                                        Address: <br />
                                        { data["ADDRESS"] && data["ADDRESS"].value }
                                    </p> */}
                                </div>
                            </div>
                            <div data-form-type="formoid">
                                <form ref={formEl} action="#" method="POST" className="mbr-form form-with-styler" data-form-title="Contact Us Form">
                                    <div className="row">
                                        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                            open={formMessage.show} onClose={handleMessageClose}>
                                            <Alert onClose={handleMessageClose} severity={formMessage.severity}>
                                                <AlertTitle><strong>{formMessage.title}</strong></AlertTitle>
                                                {formMessage.message}
                                            </Alert>    
                                        </Snackbar>
                                    </div>
                                    <div className="dragArea row">
                                        <div className={`${classes.name} col-md-6 form-group`} data-for="name">
                                            <TextField 
                                                placeholder=""
                                                label="Your Name"
                                                className="form-control input display-7 name"
                                                required
                                                value={formData.name}
                                                onChange={handleOnChange}
                                                InputProps={{
                                                    readOnly: loading,
                                                }}
                                                variant="outlined"
                                                autoFocus
                                                inputProps={{maxLength: 200}}
                                                type="text"
                                                FormHelperTextProps={{component: 'div'}}
                                                error={formHelper.name.error}
                                                helperText={
                                                    <Grid container>
                                                        { <Grid item xs={9}>{formHelper.name.message}</Grid>}
                                                        {<Grid item xs={3} align="right">{formData.name.length}/200</Grid>}
                                                    </Grid>
                                                }
                                                name="name"
                                                
                                            />  
                                        </div>
                                        <div className="col-md-6 form-group" data-for="phone">
                                            <TextField
                                                type="text"
                                                name="phone"
                                                label="Phone"
                                                data-form-field="Phone"
                                                className="form-control input display-7"
                                                id="phone-form4-4s"
                                                value={formData.phone}
                                                onChange={handleOnChange}
                                                readOnly={loading}
                                                variant="outlined"
                                                inputProps={{maxLength: 15}}
                                                FormHelperTextProps={{component: "div"}}
                                                helperText={
                                                    <Grid container>
                                                        { formHelper.phone && <Grid item xs={10}>{formHelper.phone.message}</Grid>}
                                                        {<Grid item xs={12} align="right">{formData.phone.length}/15</Grid>}
                                                    </Grid>
                                                }
                                                error={formHelper.phone && formHelper.phone.error}
                                            />
                                            {/* { chars.phone && <div style={{ textAlign: 'right' }}>{ chars.phone }/15</div> } */}
                                        </div>
                                        
                                        <div data-for="email" className="col-md-12  form-group" style={{paddingTop: '20px'}}>
                                            <TextField 
                                                placeholder="e.g. email@gmail.com"
                                                label="Email"
                                                className="form-control input display-7"
                                                required
                                                value={formData.email}
                                                onChange={handleOnChange}
                                                name="email"
                                                InputProps={{
                                                    readOnly: loading,
                                                }}
                                                variant="outlined"
                                                inputProps={{maxLength: 100}}
                                                type="text"
                                                FormHelperTextProps={{component: "div"}}
                                                error={formHelper.email.error}
                                                helperText={
                                                    <Grid container>
                                                        <Grid item xs={10}>{formHelper.email.message}</Grid>
                                                        <Grid item xs={2} align="right">{formData.email.length}/100</Grid>
                                                    </Grid>
                                                }
                                            />
                                            {/* { chars.email && <div style={{ textAlign: 'right' }}>{ chars.email }/100</div> }
                                            { errorFormData.email && <span style={{ color: 'red' }}>{ errorFormData.email }</span> } */}
                                        </div>
                                        <div className="col-md-12 form-group" data-for="subject" style={{paddingTop: '20px'}}>
                                            <TextField 
                                                placeholder=""
                                                label="Subject"
                                                className="form-control input display-7"
                                                required
                                                value={formData.subject}
                                                onChange={handleOnChange}
                                                name="subject"
                                                InputProps={{
                                                    readOnly: loading,
                                                }}
                                                variant="outlined"
                                                inputProps={{maxLength: 255}}
                                                type="text"
                                                FormHelperTextProps={{component: "div"}}
                                                error={formHelper.subject.error}
                                                helperText={
                                                    <Grid container>
                                                        <Grid item xs={10}>{formHelper.subject.message}</Grid>
                                                        <Grid item xs={2} align="right">{formData.subject.length}/255</Grid>
                                                    </Grid>
                                                }
                                            />

                                            {/* { chars.subject && <div style={{ textAlign: 'right' }}>{ chars.subject }/255</div> }
                                            { errorFormData.subject && <span style={{ color: 'red' }}>{ errorFormData.subject }</span> } */}
                                        </div>                                        
                                        <div data-for="message" className="col-md-12 form-group" style={{paddingTop: '20px'}}>
                                            <TextField 
                                                placeholder=""
                                                label="Message"
                                                className="form-control input display-7"
                                                required
                                                value={formData.message}
                                                onChange={handleOnChange}
                                                name="message"
                                                InputProps={{
                                                    readOnly: loading,
                                                }}
                                                variant="outlined"
                                                multiline
                                                rows="4"
                                                rowsMax="4"
                                                style={{height: 112}}
                                                inputProps={{maxLength: 255}}
                                                type="text"
                                                FormHelperTextProps={{component: "div"}}
                                                error={formHelper.message.error}
                                                helperText={
                                                    <Grid container>
                                                        <Grid item xs={10}>{formHelper.message.message}</Grid>
                                                        <Grid item xs={2} align="right">{formData.message.length}/255</Grid>
                                                    </Grid>
                                                }
                                            />
                                            {/* { chars.message && <div style={{ textAlign: 'right' }}>{ chars.message }/255</div> }
                                            { errorFormData.message && <span style={{ color: 'red' }}>{ errorFormData.message }</span> } */}
                                        </div>
                                        <div className="col-md-12 align-center">
                                            { !loading &&
                                            <ReCAPTCHA
                                                style={{ display: "inline-block" }}
                                                sitekey={RECAPTCHA_KEY}
                                                onChange={onChangeCaptcha}
                                                onExpired={onExpiredCaptcha}                                                
                                            />
                                            }
                                        </div>
                                        { captchaToken !== '' &&
                                            <div className={clsx("col-md-12 input-group-btn", "mt-2", "align-center", classes.wrapper)}>
                                                <button type="submit" className="btn btn-primary btn-form display-4" onClick={handleSendMessage} disabled={loading}>
                                                    SEND MESSAGE
                                            </button>
                                            </div>
                                        }
                                        { loading && <div className={classes.backdrop} /> }
                                        { loading && <CircularProgress className={classes.buttonProgress} size={100} /> }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}