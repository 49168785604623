import React from 'react';
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import '../assets/css/navbar.css'

const NavBar = ({ isTransparent }) => {
    const navBarClass = clsx("navbar", "navbar-expand", "beta-menu", "navbar-dropdown", "align-items-center", 
        "navbar-fixed-top", "navbar-toggleable-sm", "bg-color", isTransparent && "transparent" )

    let openRef;
    const handleOnMouseEnter = (event) => {
        if(!openRef) {
            openRef = event.target
            openRef.click()            
        }
    }

    const handleOnMouseLeave = () => {
        if(openRef) {
            openRef.click()
            openRef = undefined         
        }
    }
        
    return (        
        <section className={clsx("menu", isTransparent ? "cid-qTkzRZLJNu": "cid-rtVAO5iUaj")} id="menu1-0">
            <nav className={navBarClass}>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div className="menu-logo">
                    <div className="navbar-brand">
                        <span className="navbar-logo">
                            <a href="/">
                                <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Bee Solution Partners" style={{ height: '3.8rem' }} />
                            </a>
                        </span>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
                        <li className="nav-item dropdown" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                            {/* eslint-disable-next-line */}
                            <a className="nav-link link dropdown-toggle text-white display-4" href="#" data-toggle="dropdown-submenu" aria-expanded="false">
                                Our Services
                            </a>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item text-white display-4" to="/bootcamp">
                                    Bootcamp
                                </Link>
                                <Link className="dropdown-item text-white display-4" to="/itConsultant">
                                    IT Consultant
                                </Link>
                                <a className="dropdown-item text-white display-4" href="/products">
                                    Products
                                </a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link link text-white display-4" to="/portfolio">                                
                                Portfolio
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link link text-white display-4" to="/aboutUs">                                
                                About Us
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link link text-white display-4" to="/blog" aria-expanded="false">
                                Blog
                            </Link>
                        </li> */}
                    </ul>
                    <div className="navbar-buttons mbr-section-btn">
                        <Link className="btn btn-sm btn-danger display-4" to="/career">
                            Career
                        </Link>
                    </div>
                </div>
            </nav>
        </section>
    )
}
export default NavBar